import anime from "animejs";
import { AnalyticsTrackEventName } from "common/graphql/graphql.sdk";
import { getIdFromTrigger } from "../modules/controller-helpers";
import Component from "./component";

export interface ShowModalCallbackOptions {
    onCloseEnd?: (this: Modal, el: Modal["el"], closeMethod?: CloseMethod) => void;
    onGoBack?: () => void;
}

interface ModalOptions extends ShowModalCallbackOptions {
    opacity?: number;
    inDuration?: number;
    outDuration?: number;
    startingTop?: string;
    endingTop?: string;
    dismissible?: boolean;
    showCloseOrSkipButton?: boolean;
}

export type CloseMethod = "skip_button" | "overlay";

const skipCloseMethods = ["skip_button", "overlay"] as const;
export const closeMethodIsASkip = (closeMethod: CloseMethod | undefined) =>
    !!closeMethod && skipCloseMethods.includes(closeMethod);

export const mapModalCloseMethodToAnalyticsEventName = (
    closeMethod: CloseMethod | undefined
): AnalyticsTrackEventName | undefined => {
    switch (closeMethod) {
        case "skip_button":
            return "sharecard_step_skip_btn_clicked";
        case "overlay":
            return "sharecard_step_overlay_clicked";
        default:
            return undefined;
    }
};

const defaultOptions = {
    inDuration: 350,
    outDuration: 250,
    opacity: 0.5,
    onCloseEnd: undefined,
    beforeClose: undefined,
    startingTop: "2%",
    endingTop: "5%",
    dismissible: true,
    showCloseOrSkipButton: true,
};

export type OnCloseModalOption = {
    beforeCloseCallback?: () => void;
    overrideOnFinishCallback?: (modalObject: Modal, modelEl: Modal["el"]) => void;
    /** The method by which the modal was closed, useful for knowing if a modal was dismissed/cancelled vs continue successfully.
     * eg. for analytics purposes
     */
    closeMethod?: CloseMethod;
};

class Modal extends Component {
    el: HTMLElement & { M_Modal?: Modal };
    name: string;
    options: ModalOptions;
    overlay: HTMLDivElement;
    isOpen: boolean;
    reasonToClose: string | undefined;
    closeOrSkipButtons: HTMLButtonElement[] = [];
    static _count = 0;

    constructor(el: Modal["el"], options?: Modal["options"]) {
        super(Modal, el);
        this.el = el;
        this.name = "";
        this.el.M_Modal = this;
        this.options = options ? { ...defaultOptions, ...options } : defaultOptions;
        this.isOpen = el.classList.contains("open");

        this.overlay = document.createElement("div");
        this.overlay.classList.add("overlay");

        Modal._count++;
        this._setupEventHandlers();
        this._hideCloseOrSkipButtonIfNecessary();
    }

    static getInstance(el: Modal["el"]) {
        return el.M_Modal;
    }

    destroy() {
        Modal._count--;
        this._removeEventHandlers();
        this.el.removeAttribute("style");
        this.overlay.remove();
        this.el.M_Modal = undefined;

        // Enable the body scroll just in case it was disabled
        this.enableBodyScroll();
    }

    static init(el: Modal["el"], options?: Modal["options"]) {
        return new Modal(el, options);
    }

    /**
     * Setup Event Handlers
     */
    _setupEventHandlers() {
        if (Modal._count === 1) {
            document.body.addEventListener("click", this._handleTriggerClick);
        }
        this.overlay.addEventListener("click", this._handleOverlayClick);
        this.el.addEventListener("click", this._handleModalCloseClick);
        this.el.addEventListener("click", this._handleModalGoBackClick);
    }

    _hideCloseOrSkipButtonIfNecessary() {
        if (this.options.showCloseOrSkipButton) {
            return;
        }
        const closeOrSkipButtons = this.el.querySelectorAll(".modal-close");
        closeOrSkipButtons.forEach((button) => {
            if (!(button instanceof HTMLButtonElement)) {
                return;
            }

            if (getComputedStyle(button).display === "none") {
                return;
            }
            this.closeOrSkipButtons.push(button);
            button.style.visibility = "hidden";
        });
    }

    _restoreCloseOrSkipButtonIfNecessary() {
        if (this.options.showCloseOrSkipButton || this.closeOrSkipButtons.length === 0) {
            return;
        }

        this.closeOrSkipButtons.forEach((button) => {
            button.style.visibility = "unset";
        });
    }

    /**
     * Remove Event Handlers
     */
    _removeEventHandlers = () => {
        if (Modal._count === 0) {
            document.body.removeEventListener("click", this._handleTriggerClick);
        }
        this.overlay.removeEventListener("click", this._handleOverlayClick);
        this.el.removeEventListener("click", this._handleModalGoBackClick);
    };

    /**
     * Handle Trigger Click
     * @param {Event} e
     */
    _handleTriggerClick = (e: MouseEvent) => {
        const triggerEl = (e.target as Element).closest(".modal-trigger");
        if (triggerEl && triggerEl instanceof HTMLElement) {
            const modalId = getIdFromTrigger(triggerEl);
            const modalEl = document.getElementById(modalId) as Modal["el"] | null;
            const modalInstance = modalEl?.M_Modal;

            if (modalInstance) {
                modalInstance.open();
            }
            e.preventDefault();
        }
    };

    /**
     * Handle Overlay Click
     */
    _handleOverlayClick = () => {
        if (this.options.dismissible) {
            this.close({ closeMethod: "overlay" });
        }
    };

    /**
     * Handle Modal Close Click
     * @param {Event} e
     */
    _handleModalCloseClick = (e: MouseEvent) => {
        const closeTriggerEl = (e.target as Element).closest(".modal-close");
        if (closeTriggerEl && closeTriggerEl instanceof HTMLElement) {
            this.close({ closeMethod: "skip_button" });
        }
    };

    /**
     * Handle Modal Go Back Click
     * @param {Event} e
     */
    _handleModalGoBackClick = (e: MouseEvent) => {
        const goBackTriggerEl = (e.target as Element).closest(".modal-back");
        if (goBackTriggerEl && goBackTriggerEl instanceof HTMLElement && this.options.onGoBack) {
            this.close({
                // don't trigger default onclose behaviour
                overrideOnFinishCallback: this.options.onGoBack,
            });
        }
    };

    /**
     * Handle Keydown
     * @param {Event} e
     */
    _handleKeydown = (e: KeyboardEvent) => {
        // ESC key
        if ((e.key === "Escape" || e.key === "Esc") && this.options.dismissible) {
            this.close();
        }
    };

    /**
     * Handle Focus
     * @param {Event} e
     */
    _handleFocus = (e: FocusEvent) => {
        // Only trap focus if this modal is the last model opened (prevents loops in nested modals).
        if (!this.el.contains(e.target as Element)) {
            this.el.focus();
        }
    };

    /**
     * Animate in modal
     */
    _animateIn(onOpenFinishCallback?: () => void) {
        // Set initial styles
        this.el.style.display = "block";
        this.overlay.style.display = "block";
        this.overlay.style.opacity = "0";

        // Animate overlay
        anime({
            targets: this.overlay,
            opacity: this.options.opacity,
            duration: this.options.inDuration,
            easing: "easeOutQuad",
        });

        // Define modal animation options
        const baseEnterAnimOptions: anime.AnimeParams = {
            targets: this.el,
            duration: this.options.inDuration,
            easing: "easeOutCubic",
            complete: onOpenFinishCallback,
        };

        // Bottom sheet animation
        if (this.el.classList.contains("bottom-sheet")) {
            this.el.style.opacity = "1";

            anime({
                ...baseEnterAnimOptions,
                bottom: 0,
                opacity: 1,
            });

            // Normal modal animation
        } else {
            this.el.style.opacity = "0";

            anime({
                ...baseEnterAnimOptions,
                top: [this.options.startingTop, this.options.endingTop],
                opacity: 1,
                scaleX: [0.8, 1],
                scaleY: [0.8, 1],
            });
        }
    }

    /**
     * Animate out modal
     */
    _animateOut(
        overrideOnFinishCallback: OnCloseModalOption["overrideOnFinishCallback"],
        closeMethod?: CloseMethod
    ) {
        // Animate overlay
        anime({
            targets: this.overlay,
            opacity: 0.6,
            duration: this.options.outDuration,
            easing: "easeOutQuart",
        });

        // Define modal animation options
        const baseExitAnimOptions = {
            targets: this.el,
            duration: this.options.outDuration,
            easing: "easeOutCubic",
            // Handle modal ready callback
            complete: () => {
                this.el.style.display = "none";
                this.overlay.remove();

                // Call onCloseEnd callback
                if (typeof overrideOnFinishCallback === "function") {
                    overrideOnFinishCallback(this, this.el);
                } else if (!overrideOnFinishCallback && typeof this.options.onCloseEnd === "function") {
                    this.options.onCloseEnd.call(this, this.el, closeMethod);
                }
            },
        };

        // Bottom sheet animation
        if (this.el.classList.contains("bottom-sheet")) {
            anime({ ...baseExitAnimOptions, bottom: "-100%", opacity: 1 });
        } else {
            // Normal modal animation
            anime({
                ...baseExitAnimOptions,
                top: [this.options.endingTop, this.options.startingTop],
                opacity: 0,
                scaleX: 0.8,
                scaleY: 0.8,
            });
        }
    }

    /**
     * Open Modal
     */
    open(onOpenFinishCallback?: () => void) {
        if (this.isOpen) {
            return;
        }

        this.disableBodyScroll();

        this.isOpen = true;
        this.reasonToClose = undefined;

        this.el.classList.add("open");
        this.el.insertAdjacentElement("afterend", this.overlay);

        if (this.options.dismissible) {
            document.addEventListener("keydown", this._handleKeydown);
            document.addEventListener("focus", this._handleFocus, true);
        }

        anime.remove(this.el);
        anime.remove(this.overlay);
        this._animateIn(onOpenFinishCallback);

        // Focus modal
        this.el.focus();

        return this;
    }

    /**
     * Close Modal
     */
    async close(oncloseCallbacks?: OnCloseModalOption) {
        this._restoreCloseOrSkipButtonIfNecessary();

        if (!this.isOpen) {
            return;
        }

        this.isOpen = false;

        this.el.classList.remove("open");
        document.body.style.overflow = ""; // Enable body scrolling

        if (oncloseCallbacks?.beforeCloseCallback) {
            // await works here even if the function is not asynchronous.
            await oncloseCallbacks.beforeCloseCallback();
        }

        if (this.options.dismissible) {
            document.removeEventListener("keydown", this._handleKeydown);
            document.removeEventListener("focus", this._handleFocus, true);
        }

        anime.remove(this.el);
        anime.remove(this.overlay);
        this.overlay.remove();
        this._animateOut(oncloseCallbacks?.overrideOnFinishCallback, oncloseCallbacks?.closeMethod);
        this.enableBodyScroll();
        return this;
    }

    disableBodyScroll() {
        document.body.style.overflowY = "hidden";
        document.body.style.top = `-${window.scrollY}px`;
    }

    enableBodyScroll() {
        const scrollY = document.body.style.top;
        document.body.style.overflowY = "";
        document.body.style.top = "";
        window.scrollTo(0, parseInt(scrollY || "0") * -1);
    }
}

export default Modal;
