import {
    appendViewerIdCookie,
    generateLongEntityId,
    getCookie,
    generateViewerIdCookie,
} from "common/utils/helpers";
import {
    SaveContactMethod,
    MetadataWhenSendBackDetails,
    MetadataWhenClickCardField,
    CardFieldType,
} from "common/types/analytics-events";
import type { AnalyticsTrackPropertiesInput } from "common/types/graphql";
import { Card } from "common/types/cards";
import { ShareDetailsFormData } from "common/types/share-details";
import { CARD_PAGE_VIEWER_ID_KEY } from "../constants";
import { ReferralDeeplinkBuilder } from "../referral-deeplink-builder";
import { analytics } from "./index";
import { HOSTNAME_ME } from "../../env";

export interface CommonMetadata extends AnalyticsTrackPropertiesInput {
    card_id: string;
    card_edit_id: string;
    session_id: string;
    card_user_id: string;
    viewer_id: string;
    card_org_id?: string;
    email_signature_id?: string;
}

export function addMetadataWhenSendBackDetails(
    parsedFormData: ShareDetailsFormData
): MetadataWhenSendBackDetails {
    return {
        is_sending_back_first_name: !!parsedFormData.first_name,
        is_sending_back_last_name: !!parsedFormData.last_name,
        is_sending_back_email: !!parsedFormData.email,
        is_sending_back_phone_number: !!parsedFormData.phone_number,
        is_sending_back_job_title: !!parsedFormData.job_title,
        is_sending_back_org_name: !!parsedFormData.org_name,
    };
}

export function addMetadataWhenClickCardField(
    fieldType: CardFieldType,
    urlType: string
): MetadataWhenClickCardField {
    return {
        clicked_field_type: fieldType,
        clicked_field_url_type: urlType,
    };
}

export let storeInstance: Store;

/**
    An event tracking wrapper for Cloudflare zaraz
    @ref https://developers.cloudflare.com/zaraz/web-api/
*/
class Store {
    startTime: number;
    private commonMetadata: AnalyticsTrackPropertiesInput;
    userHasFilledShareDetailsForm: boolean;

    saveContactMethod: SaveContactMethod;

    constructor(cardData: Card) {
        this.startTime = Date.now();
        this.commonMetadata = this.storeCardData(cardData);
        this.userHasFilledShareDetailsForm = false;
        this.saveContactMethod = "save";

        analytics.set("anonymous_id", this._initialiseCardViewerId(), { scope: "persist" });
    }

    // properties.xx doesn't work well
    private storeCardData(cardData: Card): AnalyticsTrackPropertiesInput {
        const viewerId = this._initialiseCardViewerId();
        const sessionId = generateLongEntityId();
        analytics.set("card_id", cardData.cardId, { scope: "page" });
        analytics.set("card_update_id", cardData.cardUpdateId, { scope: "page" });
        if (cardData.emailSignatureId) {
            analytics.set("email_signature_id", cardData.emailSignatureId, { scope: "page" });
        }
        if (cardData.userId) analytics.set("card_user_id", cardData.userId, { scope: "page" });
        if (cardData.orgId) analytics.set("card_org_id", cardData.orgId, { scope: "page" });

        analytics.set("session_id", sessionId, { scope: "session" });
        analytics.set("viewer_id", viewerId, { scope: "persist" });
        return {
            card_id: cardData.cardId,
            card_update_id: cardData.cardUpdateId,
            viewer_id: viewerId,
            card_user_id: cardData.userId || "",
            card_org_id: cardData.orgId,
            session_id: sessionId,
            email_signature_id: cardData.emailSignatureId,
        };
    }

    private _initialiseCardViewerId(): string {
        const viewerIdMetaTag = document.querySelector('meta[name="viewer_id"]');
        const viewerId = viewerIdMetaTag?.getAttribute("content");
        if (!viewerId) {
            const cookies = document.cookie;
            const viewerIdInCookie = getCookie<string>(cookies, CARD_PAGE_VIEWER_ID_KEY);
            if (viewerIdInCookie) {
                return viewerIdInCookie;
            }
            const viewer = generateViewerIdCookie(HOSTNAME_ME ?? window.location.host);
            document.cookie = viewer.cookieString;
            return viewer.viewerId;
        }
        document.cookie = appendViewerIdCookie(viewerId, HOSTNAME_ME ?? window.location.host);
        return viewerId;
    }

    addReferralDataToEncodedLink(encodedLink: ReferralDeeplinkBuilder) {
        encodedLink
            .addLinkQueryParam("ref_card_id", this.commonMetadata.card_id?.toString())
            .addLinkQueryParam("ref_card_update_id", this.commonMetadata.card_update_id?.toString())
            .addLinkQueryParam("ref_viewer_id", this.commonMetadata.viewer_id?.toString())
            .addLinkQueryParam("ref_card_user_id", this.commonMetadata.card_user_id?.toString())
            .addLinkQueryParam("ref_card_org_id", this.commonMetadata.card_org_id?.toString())
            .addLinkQueryParam("ref_session_id", this.commonMetadata.session_id?.toString())
            .addLinkQueryParam("ref_email_signature_id", this.commonMetadata.email_signature_id?.toString())
            .addLinkQueryParam(
                "ref_has_filled_share_details_form",
                String(this.userHasFilledShareDetailsForm)
            );
    }

    getReferralData() {
        return {
            ref_card_id: this.commonMetadata.card_id,
            ref_card_update_id: this.commonMetadata.card_update_id,
            ref_viewer_id: this.commonMetadata.viewer_id,
            ref_card_user_id: this.commonMetadata.card_user_id,
            ref_card_org_id: this.commonMetadata.card_org_id,
            ref_session_id: this.commonMetadata.session_id,
            ref_email_signature_id: this.commonMetadata.email_signature_id,
            ref_has_filled_share_details_form: this.userHasFilledShareDetailsForm,
        };
    }
}

/**
    Initialise store object with card data
    @cardData
*/
export function initialiseAnalyticsStore(cardData: Card) {
    storeInstance = new Store(cardData);
}
