const INPUT_SELECTORS =
    "input[type=text], input[type=password], input[type=email], input[type=url], input[type=tel], input[type=number], input[type=search], input[type=date], input[type=time], textarea";

function updateTextFields() {
    document.querySelectorAll<HTMLInputElement | HTMLTextAreaElement>(INPUT_SELECTORS).forEach((element) => {
        if (
            element.value.length > 0 ||
            element === document.activeElement ||
            element.autofocus ||
            element.getAttribute("placeholder") !== null
        ) {
            activateSiblingLabel(element);
        } else if (element.validity) {
            toggleSiblingLabelActivation(element, element.validity.badInput === true);
        } else {
            deactivateSiblingLabel(element);
        }
    });
}

export function toggleErrorState(
    textInputEl: HTMLInputElement | HTMLElement | null,
    isError: boolean,
    errorMessage: string
) {
    if (!textInputEl) {
        return;
    }
    textInputEl.toggleAttribute("data-error", isError);
    if (!isError) {
        return;
    }
    const errorEl = getSiblingElementsByClassName(textInputEl, "error-text")?.[0];
    if (errorEl) {
        errorEl.textContent = errorMessage;
    }
}

function getSiblingElementsByClassName(element: HTMLElement, className: string) {
    const parentNode = element.parentNode;
    if (parentNode instanceof HTMLElement) {
        return parentNode?.getElementsByClassName(className);
    }
}

function toggleSiblingLabelActivation(
    element: HTMLInputElement | HTMLTextAreaElement,
    isActive: boolean
): void {
    getSiblingElementsByClassName(element, "label-text")?.[0]?.classList.toggle("active", isActive);
}

function activateSiblingLabel(element: HTMLInputElement | HTMLTextAreaElement): void {
    getSiblingElementsByClassName(element, "label-text")?.[0]?.classList.add("active");
}

function deactivateSiblingLabel(element: HTMLInputElement | HTMLTextAreaElement): void {
    getSiblingElementsByClassName(element, "label-text")?.[0]?.classList.remove("active");
    getSiblingElementsByClassName(element, "blinq-input")?.[0]?.toggleAttribute("data-error", false);
}

function handleLoseFocusEvent(event: Event) {
    if (event.target instanceof HTMLInputElement || event.target instanceof HTMLTextAreaElement) {
        if (event.target.value.length !== 0 || event.target.getAttribute("placeholder") !== null) {
            activateSiblingLabel(event.target);
        }

        if (
            event.target.value.length === 0 &&
            event.target.validity.badInput !== true &&
            event.target.getAttribute("placeholder") === null
        ) {
            deactivateSiblingLabel(event.target);
        }
    }
}

export function initialise() {
    window.addEventListener("DOMContentLoaded", () => {
        const forms = document.querySelectorAll<HTMLFormElement>("form");
        const inputEls = document.querySelectorAll<HTMLInputElement | HTMLTextAreaElement>(INPUT_SELECTORS);

        forms.forEach((formEl: HTMLFormElement) => {
            formEl.addEventListener("reset", (event) => {
                if (!(event.target instanceof HTMLFormElement)) return;
                // Reset the input label when the form is reset.
                // This will restore the label position to the default position after reset
                event.target
                    .querySelectorAll<HTMLInputElement | HTMLTextAreaElement>(INPUT_SELECTORS)
                    .forEach((el) => deactivateSiblingLabel(el));
            });
        });

        inputEls.forEach((el) => {
            el.addEventListener("change", handleLoseFocusEvent);

            el.addEventListener("input", (event: Event) => {
                if (event.target instanceof HTMLInputElement || event.target instanceof HTMLTextAreaElement) {
                    const blinqInputEl =
                        event.target.closest(".blinq-input") ?? event.target.closest(".blinq-text-field");
                    if (blinqInputEl && blinqInputEl.getAttribute("data-error") !== null) {
                        // Inputs may sometime belong to validation groups, where the group can either be all valid or invalid.
                        // We need to check if this input belongs to a group, and if so validate all inputs in this group.
                        const validationGroup = blinqInputEl.getAttribute("data-validate-group");

                        if (validationGroup) {
                            const allGroupInputEls = document.querySelectorAll(
                                `[data-validate-group="${validationGroup}"]`
                            );
                            allGroupInputEls.forEach((el) => el.toggleAttribute("data-error", false));
                        } else {
                            blinqInputEl.toggleAttribute("data-error", false);
                        }
                    }
                }
            });

            el.addEventListener("blur", handleLoseFocusEvent);
        });

        updateTextFields();
    });
}
