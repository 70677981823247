import anime, { AnimeInstanceParams } from "animejs";
import { AnimationItem } from "lottie-web";

export const progressBarAnimation = (container: string, duration = 2000) => ({
    animation: (options: AnimeInstanceParams = {}) =>
        anime({
            targets: `${container} .progress`,
            easing: "easeInSine",
            width: `calc(100% - 3rem)`,
            duration,
            direction: "linear",
            ...options,
        }),
});

/** A global map that stores all the lottie animation instances */
const lottieAnimationInstance: Map<string, AnimationItem> = new Map();

export function saveLottieAnimationInstance(animationName: string, animation: AnimationItem) {
    lottieAnimationInstance.set(animationName, animation);
}

export function getLottieAnimationInstance(animationName: string): AnimationItem | undefined {
    return lottieAnimationInstance.get(animationName);
}
