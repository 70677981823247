import anime from "animejs";
import { CardUrlType } from "common/types/card-url";
import { CardAddress, CardEmail, CardPhoneNumber, CardUrl } from "common/types/cards";
import { expireCookie, getCookie } from "common/utils/helpers";
import { mapModalCloseMethodToAnalyticsEventName } from "../..//controllers/modal";
import { API_BASE_URL, EMAIL_DOMAIN_WORKER_URL, HOSTNAME_ME } from "../../env";
import { progressBarAnimation } from "../../modules/animations";
import { blinqApiGraphQLClient } from "../../modules/api";
import {
    TextFieldTypes,
    buildTextFieldForShareDetailsBackForm,
} from "../../templates/partials/text-field-builders";
import { getGraphQLError, isGraphQLError } from "../../util/graphql-request-helper";
import { analytics } from "../analytics";
import { storeInstance } from "../analytics/store";
import { PaperDesignSystemToast } from "../components/paper-design-system-toast";
import { GOOGLE_PEOPLE_AUTH_COOKIE, OPAQUE_TOKEN_KEY } from "../constants";
import {
    closeAndroidExplainerModal,
    closeSaveContactModal,
    hideDownloadContactButtonFromSaveContactModal,
    showSaveContactModal,
    showSelectCountryModal,
} from "../modals";
import { ReferralDeeplinkBuilder } from "../referral-deeplink-builder";
import {
    addStoreParams,
    autoFillShareDetailsBackForm,
    downloadVCard,
    fromHTMLString,
    getCardData,
    getDateOfExchange,
    getLocationOfExchange,
    getVCardLocation,
    resetSaveContactForm,
} from "../utils";
import { showSaveContactModalWithDefaultBehavior } from "./explainer";
import { showAndroidExplainerModalWithDefaultBehavior } from "./save-contact";
import { startSendContactBackFlow } from "./send-contact";

export async function createLoginToken() {
    const tokenResponse = await blinqApiGraphQLClient.createOneTimeLoginToken();
    return tokenResponse?.createOneTimeLoginToken ?? null;
}

export async function onSaveToGoogleContactsBtnClick(btnEl: HTMLElement) {
    analytics.track({
        name: "contact_saved",
        properties: { save_card_method: "google_contacts_api" },
    });

    await handleGoogleSaveContactBtnClick(btnEl);
}

export async function handleGoogleSaveContactBtnClick(btnEl: HTMLElement) {
    const hostname = window.location.hostname;
    const didAuthWithGoogle = getCookie(document.cookie, GOOGLE_PEOPLE_AUTH_COOKIE);
    const { cardId } = getCardData();

    const handleAuth = () => {
        analytics.track({
            name: "google_auth_page_landed",
            properties: { save_card_method: "google_contacts_api" },
        });
        const currentUrl = window.location.href;
        const state = encodeURIComponent(JSON.stringify({ cardId, redirect: currentUrl }));
        window.location.href = `${API_BASE_URL}/people/connect?state=${state}`;
    };

    // Get authenticated with Google if the user hasn't did that.
    if (!didAuthWithGoogle) {
        handleAuth();
        return;
    }

    try {
        btnEl.dataset.loading = "true";

        await blinqApiGraphQLClient.addContact({
            syncToGoogle: true,
            cardId,
            location: getLocationOfExchange(),
        });

        analytics.track({
            name: "contact_saved_done",
            properties: { save_card_method: "google_contacts_api" },
        });
        closeAndroidExplainerModal({
            beforeCloseCallback: () => {
                displaySaveContactToast("done", true);
            },
        });
    } catch (error) {
        if (isGraphQLError(error)) {
            const { extensions } = getGraphQLError(error)[0];
            const code = extensions?.code;
            if (code === "FORBIDDEN" || code === "UNAUTHORIZED") {
                document.cookie = expireCookie(GOOGLE_PEOPLE_AUTH_COOKIE, hostname, "/");
                handleAuth();
                return;
            }
        }

        // Unknown error
        closeAndroidExplainerModal({
            overrideOnFinishCallback: () => {
                displaySaveContactToast("error", true);
                showSaveContactViaEmailInsteadModal(showAndroidExplainerModalWithDefaultBehavior);
            },
        });
    } finally {
        document.cookie = expireCookie("step", hostname, "/");
        btnEl.dataset.loading = "false";
    }
}

/**
 * This function mainly resets the save contact form and start the sharing details back
 * to card's owner flow.
 */
function cleanUpWhenSaveContactModalCloses() {
    const saveContactModalEl = document.getElementById("save-contact-modal") as HTMLElement;
    saveContactModalEl.setAttribute(
        "data-tab",
        saveContactModalEl.getAttribute("data-default-tab") || "email"
    );

    const cardData = getCardData();
    startSendContactBackFlow(
        cardData.displayGetBlinqPrompt ?? true,
        showSaveContactModalWithDefaultBehavior,
        true
    );
    autoFillShareDetailsBackForm();
    resetSaveContactForm();
}

export function showSaveContactViaEmailInsteadModal(onGoBack: () => void) {
    hideDownloadContactButtonFromSaveContactModal("Receive card via email instead");
    showSaveContactModal({
        onGoBack: () => {
            onGoBack();
            closeSaveContactModal();
        },

        onCloseEnd: (_, closeMethod) => {
            const closeModalEventName = mapModalCloseMethodToAnalyticsEventName(closeMethod);

            if (closeModalEventName) {
                analytics.track({
                    name: closeModalEventName,
                    properties: { page_id: "send_details_to_self_modal" },
                });
            }

            cleanUpWhenSaveContactModalCloses();
        },
    });
}

/**
 * This function will download the card and let the user save the contact details to
 * their phone. This usually happens when the browser allows to save the contact but
 * the explainer modal does not make sense, thus they have to do it inside the save
 * contact modal instead.
 *
 */
export function handleDownloadContactBtnClick() {
    const cardData = getCardData();
    storeInstance.saveContactMethod = "save";

    closeSaveContactModal({
        beforeCloseCallback: downloadVCard,
        overrideOnFinishCallback: () => {
            startSendContactBackFlow(
                cardData.displayGetBlinqPrompt ?? true,
                showSaveContactModalWithDefaultBehavior,
                true
            );
        },
    });
}

/**
 * This function will download the card and clean up the button animation in the explainer modal.
 *
 */
async function downloadVCardAndCleanupInExplainerModal() {
    storeInstance.saveContactMethod = "save";
    downloadVCard();
    const { animation } = progressBarAnimation(".modal-btn-container");
    const doneAnimation = animation({ autoplay: false });
    try {
        // Play the animation
        doneAnimation.play();
        // Wait for the thing to download
        await fetch(getVCardLocation());
    } catch (error) {
        // if there's an error then wait for the animation to finish
        await doneAnimation.finished;
    } finally {
        // reset the animation
        doneAnimation.seek(0);
        doneAnimation.pause();
    }
}

export type AddMoreDetailsPillButtonTypes = "job_title" | "org_name";

/**
 * This function will handle the click event of the pill buttons in the adaptive sharing experiment.
 * It will animate the text field component to appear and the clicked pill button to disappear.
 * @param event
 * @param type The type of the clicked pill button
 * @returns
 */
export function handleAddMoreInfoPillButtonClick(event: Event, type: AddMoreDetailsPillButtonTypes) {
    event.preventDefault();
    event.stopPropagation();

    const shareDetailsBackElement = document.getElementById("share-contact-info-modal");
    if (!shareDetailsBackElement) {
        return;
    }

    const scrollableContentContainer = shareDetailsBackElement.getElementsByClassName(
        "modal-scrollable-content"
    )[0] as HTMLElement;

    if (!scrollableContentContainer) {
        return;
    }

    const modalFormInputsElement = document.getElementsByClassName("modal-form-inputs")[0] as HTMLElement;

    let textFieldComponent: HTMLElement;
    let clickedPillButton: HTMLElement;
    switch (type) {
        case "job_title":
            textFieldComponent = fromHTMLString(
                buildTextFieldForShareDetailsBackForm(TextFieldTypes.JOB_TITLE)
            ) as HTMLElement;
            clickedPillButton = document.getElementById("add-job-title-pill-button") as HTMLElement;
            break;
        case "org_name":
            textFieldComponent = fromHTMLString(
                buildTextFieldForShareDetailsBackForm(TextFieldTypes.COMPANY_NAME)
            ) as HTMLElement;
            clickedPillButton = document.getElementById("add-company-name-pill-button") as HTMLElement;
            break;
    }

    if (clickedPillButton.getAttribute("data-has-been-removed") === "true") {
        return;
    }

    const shareDetailsBackElementHeight = parseInt(getComputedStyle(shareDetailsBackElement).height);
    const shareDetailsBackElementMaxHeight = parseInt(getComputedStyle(shareDetailsBackElement).maxHeight);
    const pillButtonsContainer = document.getElementById("add-more-info-button-container") as HTMLElement;
    const remainingPillButtons = [];
    pillButtonsContainer.querySelectorAll(".add-more-pill").forEach((pillButton) => {
        if (pillButton.getAttribute("data-has-been-removed") !== "true") {
            remainingPillButtons.push(pillButton);
        }
    });
    const isTheLastPillButton = remainingPillButtons.length === 1;
    const clickedButtonHeight = clickedPillButton.getBoundingClientRect().height;
    const counterPartPillButton = clickedPillButton.nextElementSibling as HTMLElement | null;
    clickedPillButton.setAttribute("data-has-been-removed", "true");

    /**
     * This function will animate the removal of the clicked pill button.
     * If the clicked pill button is the first button, we will animate the transition of the
     * counter part pill button to make it look like it is replacing the clicked button. Else
     * we will simply animate the clicked button to disappear.
     */
    function animateClickedButtonRemovalTransition() {
        const isTheClickedPillButtonTheFirstButton =
            counterPartPillButton &&
            counterPartPillButton.style.display !== "none" &&
            counterPartPillButton.getAttribute("data-animate-removal") !== "true";
        if (isTheClickedPillButtonTheFirstButton) {
            anime({
                targets: clickedPillButton,
                opacity: [1, 0],
                duration: 100,
                easing: "linear",
            });

            counterPartPillButton.toggleAttribute("data-animate-removal", true);
            counterPartPillButton.ontransitionend = () => {
                counterPartPillButton.style.transition = "none";
                counterPartPillButton.style.transform = "unset";
                clickedPillButton.remove();
            };
            counterPartPillButton.ontransitioncancel = () => {
                clickedPillButton.remove();
            };
        } else {
            clickedPillButton.style.position = isTheLastPillButton ? "absolute" : "default";
            anime({
                targets: clickedPillButton,
                opacity: [1, 0],
                duration: 200,
                easing: "easeInOutQuad",
                complete: () => {
                    clickedPillButton.remove();
                },
            });
        }
    }
    animateClickedButtonRemovalTransition();

    textFieldComponent.style.opacity = "0";
    modalFormInputsElement.appendChild(textFieldComponent);
    const textFieldComponentHeight = parseInt(getComputedStyle(textFieldComponent).height);

    const newShareDetailsBackElementHeight = shareDetailsBackElementHeight + textFieldComponentHeight + 20;
    const updatedHeight = isTheLastPillButton
        ? newShareDetailsBackElementHeight - clickedButtonHeight
        : newShareDetailsBackElementHeight;
    document.body.style.setProperty("--share-contact-info-modal-height", `${updatedHeight}px`);

    /// If the new height of the modal is greater than the max height, we will simply display the text field,
    /// no need to do gymnastics with the animation
    if (newShareDetailsBackElementHeight > shareDetailsBackElementMaxHeight) {
        scrollableContentContainer.scrollBy({
            top: scrollableContentContainer.scrollHeight - scrollableContentContainer.scrollTop,
            behavior: "instant" as ScrollBehavior,
        });
        anime({
            targets: textFieldComponent,
            opacity: [0, 1],
            duration: 300,
            easing: "easeInOutQuad",
            delay: 50, /// To prevent the text field from appearing too soon and cause jumpy animation
        });

        return;
    }

    /// Temporarily make the text field position absolute and set the width to 100% to make it appear as if
    /// it slowly appears from the bottom of the modal
    textFieldComponent.style.position = "absolute";
    textFieldComponent.style.width = "100%";

    anime({
        targets: textFieldComponent,
        translateY: ["25%", 0],
        opacity: [0, 1],
        duration: 300,
        easing: "easeInOutQuad",
        complete: () => {
            shareDetailsBackElement.style.overflowX = "unset";
            scrollableContentContainer.style.overflowX = "unset";
            textFieldComponent.style.position = "unset";
            textFieldComponent.style.width = "unset";
            scrollableContentContainer.scrollTop = scrollableContentContainer.scrollHeight;
        },
    });
}

export async function getDomainIfNotPublic(email: string) {
    const splitEmail = email.split("@");

    if (splitEmail.length !== 2) {
        return null;
    }

    const emailDomain = splitEmail[1];

    try {
        const response = await fetch(`${EMAIL_DOMAIN_WORKER_URL}/${emailDomain}`);

        if (!response.ok) {
            return null;
        }

        const data = await response.json();

        if (data?.is_generic_provider) {
            return null;
        }

        return emailDomain;
    } catch (error) {
        console.error("Failed to fetch domain validation:", error);
        return null;
    }
}

export function displaySaveContactToast(
    type: "done" | "error",
    isGoogleContacts: boolean,
    errorCode?: string
) {
    const cardData = getCardData();
    let primaryText = "Whoops! Something went wrong.";
    if (type === "done") {
        // success
        primaryText = cardData.firstName ? `${cardData.firstName}’s contact` : "This card";
        primaryText += " has been saved";
    }
    if (type === "error") {
        // failure message
        switch (errorCode) {
            case "access_denied":
                primaryText = "Sign in process has been canceled";
                break;
            case "invalidInvocationUrl": // these are from App clips in 'saved_contact_error' param
            case "invalidCardDetails":
            case "invalidVcardSourceUrl":
            case "downloadContactFailed":
                primaryText = "An unexpected internal error has occurred";
                break;
            default:
                primaryText = "Whoops! Something went wrong. Please try again.";
                break;
        }
    }
    const { render: renderSaveContactToast } = PaperDesignSystemToast({
        primaryText: primaryText,
        duration: 3000,
        toastType: type === "done" ? "success" : "error",
    });
    renderSaveContactToast();
}

export function handleSelectCountryBtnClick() {
    showSelectCountryModal();
}

export function handleFieldClick(
    type: "url" | "email" | "phone_number" | "address",
    field: CardUrl | CardEmail | CardPhoneNumber | CardAddress
) {
    try {
        let domain: string | null = null;
        let urlType: CardUrlType | null = null;
        switch (type) {
            case "email": {
                const split = field.value.split("@");
                domain = split.length > 0 ? split[1] : null;
                break;
            }
            case "url": {
                const url = new URL(field.value);
                domain = url.hostname;
                break;
            }
        }
        if ("type" in field) {
            urlType = field.type;
        }
        analytics.track({
            name: "card_field_clicked",
            properties: {
                page_id: "card",
                clicked_field_type: type,
                clicked_field_url_type: urlType,
                clicked_field_domain: domain,
            },
        });
    } catch (error) {
        console.error("Failed to track", { error });
    }
}

export function handleCreateMyCardBtnClick() {
    analytics.track({
        name: "onboarding_createcard_btn_clicked",
    });

    downloadApp({ skipTrack: true });
}

/**
 * Navigate to a route we have that will redirect the user to a deep link
 */
export function downloadApp(options?: { skipTrack?: boolean }) {
    const linkTo = HOSTNAME_ME ? `https://${HOSTNAME_ME}/signup` : "http://localhost:8787/signup"; // fallback localhost for dev
    const deeplink = new ReferralDeeplinkBuilder(linkTo);
    const cardData = getCardData();
    const locationOfExchange = getLocationOfExchange();
    const dateExchangedCard = getDateOfExchange();

    // we got the token earlier to make this button click snappy
    const loginToken = sessionStorage.getItem(OPAQUE_TOKEN_KEY);

    const shareDetailsFormData = Blinq.getShareDetailsFormData();

    const shouldSkipTrack = options?.skipTrack === true;
    if (!shouldSkipTrack) {
        analytics.track({ name: "download_blinq_clicked" });
    }

    const urlParams = new URLSearchParams(window.location.search);
    const blinqSourceValue = urlParams.get("bs");

    if (blinqSourceValue) {
        deeplink.addLinkQueryParam("bs", blinqSourceValue);
    }

    deeplink
        .addLinkQueryParam("_dl", "shareCard")
        .addLinkQueryParam("userId", cardData.userId)
        .addLinkQueryParam("cardId", cardData.cardId)
        .addLinkQueryParam("cardUpdateId", cardData.cardUpdateId)
        .addLinkQueryParam("orgId", cardData.orgId) // todo: add workspace ID
        .addLinkQueryParam("l", locationOfExchange.replace(/\s/g, "ß+")) // because of encoding bug
        .addLinkQueryParam("d", dateExchangedCard.full)
        .addLinkQueryParam("dd", dateExchangedCard.day)
        .addLinkQueryParam("dm", dateExchangedCard.month)
        .addLinkQueryParam("dy", dateExchangedCard.year)
        .addLinkQueryParam("firstName", shareDetailsFormData.first_name?.replace(/\s/g, "+"))
        .addLinkQueryParam("lastName", shareDetailsFormData.last_name?.replace(/\s/g, "+"))
        .addLinkQueryParam("email", shareDetailsFormData.email?.replace(/\s/g, "+"))
        .addLinkQueryParam("jobTitle", shareDetailsFormData.job_title?.replace(/\s/g, "+"))
        .addLinkQueryParam("orgName", shareDetailsFormData.org_name?.replace(/\s/g, "+"))
        .addLinkQueryParam("phoneNumber", shareDetailsFormData.phone_number?.replace(/\s/g, "+"))
        .addLinkQueryParam("n", cardData.firstName?.replace(/\s/g, "+"))
        // This stops the intemediary page from showing up
        .addDeepQueryParam("efr", "1");

    if (loginToken) {
        deeplink.addLinkQueryParam("opaque_token", loginToken);
    }

    storeInstance.addReferralDataToEncodedLink(deeplink);

    addStoreParams(deeplink);
    window.location.href = deeplink.build();
}

export function handleScrollToTop(btnEl: HTMLButtonElement) {
    const scrollableContentEl = btnEl.parentElement?.nextElementSibling;

    if (scrollableContentEl) {
        anime({
            targets: scrollableContentEl,
            scrollTop: 0,
            duration: 500,
            easing: "easeInOutQuad",
        });
    }
}

/**
 * In the share details modal, we initially hide the job title and company name fields,
 * if the user clicks the "Add more information", we will show these fields
 */
export function handleAddMoreInformation(event: Event) {
    event.preventDefault();
    event.stopPropagation();

    analytics.track({
        name: "details_reciprocal_more_information",
        properties: { page_id: "share_contact_details_modal" },
    });

    expandExchangeInfoForm();
}

export function expandExchangeInfoForm() {
    const sendDetailsBackModalEl = document.getElementById("share-contact-info-modal") as HTMLElement;
    sendDetailsBackModalEl.classList.add("show-entire-form");
}

export function handleTurnstileBeforeInteractive() {
    const button = document.querySelector("form#send-via-sms-form button[type=submit]");
    const turnstile = document.querySelector("#turnstile-container");

    if (button) {
        button.classList.add("hidden");
    }

    if (turnstile) {
        turnstile.classList.remove("hidden");
    }
}

export function handleTurnstileAfterInteractive() {
    const button = document.querySelector("form#send-via-sms-form button[type=submit]");
    const turnstile = document.querySelector("#turnstile-container");

    if (button) {
        button.classList.remove("hidden");
    }

    if (turnstile) {
        turnstile.classList.add("hidden");
    }
}
