/**
 * Gets id of component from a trigger
 * @param {Element} trigger trigger
 * @returns {string}
 */
export function getIdFromTrigger(trigger: HTMLElement) {
    let id = trigger.getAttribute("data-target");
    if (!id) {
        id = trigger.getAttribute("href");
        if (id) {
            id = id.slice(1);
        } else {
            id = "";
        }
    }
    return id;
}
