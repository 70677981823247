import { mapModalCloseMethodToAnalyticsEventName } from "../../controllers/modal";
import { prefillShareDetails } from "../../modules/prefill";
import { analytics } from "../analytics";
import { EXPERIMENT_1_FLAG } from "../constants";
import { showShareContactDetailsModal } from "../modals";
import {
    hidePrivacyStatementInSendContactsBackModal,
    resetSaveContactForm,
    shouldShowReciprocalContactDetailsModal,
    showPrivacyStatementInSendContactsBackModal,
} from "../utils";
import { displayGetBlinqPromptIfNecessary } from "./get-blinq";

/**
 * This flow will show the modal that allows the users to share their details back
 * to the card owner. It will either hide or show privacy statement. When the share
 * contact modal is closed, it will reset the form and start the Get Blinq flow.
 * @param displayGetBlinqPrompt a flag to indicate if we should display Get Blinq modal
 * @param onGoBackCallBackForSendContactModal a callback function to be called when the user clicks go back
 * @param showPrivacyStatement a flag to indicate if we should show the privacy statement
 */
export function startSendContactBackFlow(
    displayGetBlinqPrompt: boolean,
    onGoBackCallBackForSendContactModal: () => void,
    showPrivacyStatement: boolean
) {
    if (showPrivacyStatement) {
        showPrivacyStatementInSendContactsBackModal();
    } else {
        hidePrivacyStatementInSendContactsBackModal();
    }

    if (shouldShowReciprocalContactDetailsModal()) {
        showShareContactDetailsModal({
            callbackOptions: {
                onCloseEnd: (_, closeMethod) => {
                    // There's no onClick handler for closing the modal
                    // so we're just doing the track event for 'Skip' button here
                    if (EXPERIMENT_1_FLAG) {
                        const closeModalEventName = mapModalCloseMethodToAnalyticsEventName(closeMethod);

                        if (closeModalEventName) {
                            analytics.track({
                                name: closeModalEventName,
                                properties: { page_id: "share_contact_details_modal" },
                            });
                        }
                    }
                    resetSaveContactForm();
                    prefillShareDetails();

                    // If the user skips this modal, we should show the Get Blinq ("Download Blinq") modal
                    // NOT the create card modal
                    // const skipCreateCard = closeMethodIsASkip(closeMethod);

                    displayGetBlinqPromptIfNecessary();
                },

                onGoBack: () => {
                    analytics.track({
                        name: "sharecard_step_back_btn_clicked",
                        properties: { page_id: "share_contact_details_modal" },
                    });

                    onGoBackCallBackForSendContactModal();
                },
            },
        });
        return;
    }

    displayGetBlinqPromptIfNecessary();
}
