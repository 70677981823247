import anime from "animejs";
import type { CountryCode } from "libphonenumber-js";
import { formatIncompletePhoneNumber, getCountryCallingCode } from "libphonenumber-js/mobile";
import Lottie from "lottie-web";
import lozad from "lozad";

import { initialise } from "../controllers";
import {
    isAndroid,
    isBlacklistedBrowserType,
    isBraveBrowser,
    isChromeIOS,
    isDuckDuckGoBrowser,
    isIOS,
    isMac,
    isMobile,
    isSafari,
    isSamsungBrowser,
} from "../modules/platform-detection";

import type { Card } from "common/types/cards";
import type { ShareDetailsFormData } from "common/types/share-details";
import { blinqApiGraphQLClient } from "../modules/api";
import { closeSelectCountryModal, hideDownloadContactButtonFromSaveContactModal } from "./modals";
import {
    hideRecommendationForFirefoxBraveUserOnIOS,
    monitorAdaptiveSaveContactButtonContainer,
    monitorShareContactDetailsScroll,
    playHapticFeedback,
    shouldWeAllowDownloadButNotShowExplainerOnIOS,
    shouldWeHideDownloadContactTab,
    shouldWeShowAndroidExplainerModal,
    shouldWeShowIOSExplainerModal,
    showRecommendationForFirefoxBraveUserOnIOS,
} from "./utils";

import { saveLottieAnimationInstance } from "../modules/animations";
import { prefillShareDetails } from "../modules/prefill";
import "../styles/card.scss";
import "../styles/flags.scss";
import "../styles/index.scss";
import { analytics } from "./analytics";
import { initialiseAnalyticsStore, storeInstance } from "./analytics/store";
import {
    ANDROID_SAVE_TO_CONTACTS,
    CONGRATULATION_GET_BLINQ_ANIMATION_NAME,
    EXPERIMENT_1_FLAG,
    GET_BLINQ_STAGE_1_ANIMATION_NAME,
    GET_BLINQ_STAGE_2_ANIMATION_NAME,
    IOS_SAVE_CONTACT_EXPLAINER_ANIMATION_NAME,
    QR_LINK_SUBDOMAINS_FLAG_KEY,
    SAVE_DETAILS_FORM_CACHE_KEY,
} from "./constants";
import {
    displaySaveContactToast,
    expandExchangeInfoForm,
    handleGoogleSaveContactBtnClick,
    showSaveContactViaEmailInsteadModal,
} from "./events/ui";

import { AnalyticsPagePropertiesInput } from "common/types/graphql";
import { getCookie } from "common/utils/helpers";
import { checkIncognito } from "./events/app-clip";
import { showAndroidExplainerModalWithDefaultBehavior } from "./events/save-contact";
import { startSendContactBackFlow } from "./events/send-contact";

// This script is deferred so it is the last script to be executed. As a result we know it's safe to check for the JSON
// encoded script containing the card data (this is supplied by the Cloudflare Worker HTMLRewriter)
const dataJsonElement = document.getElementById("data") as HTMLScriptElement;
const cardData = JSON.parse(dataJsonElement.text) as Card;

// Set up all our components (e.g. inputs)
initialise();

document.addEventListener("DOMContentLoaded", async function () {
    //monitorSaveBtnStickiness();
    monitorShareContactDetailsScroll();
    monitorAdaptiveSaveContactButtonContainer();

    await trackExperimentVariantId(cardData);

    // We have to do this because Datadog is set up using CDN method
    if (window.DD_RUM) {
        try {
            window.DD_RUM.onReady(async function () {
                window.DD_RUM?.setGlobalContextProperty("is_incognito", (await checkIncognito()).toString());
            });
        } catch (error) {
            console.error("Failed to configure Datadog on client side", error);
        }
    }

    setup(cardData);

    const observer = lozad(); // lazy loads elements with default selector as '.lozad'
    observer.observe();
});

async function trackExperimentVariantId(cardData: Card) {
    initialiseAnalyticsStore(cardData);

    const cookies = document.cookie;

    // New experiment tracking logic below, talk to Data team to set up a key, or use an existing one if the experiment is not longer active
    // App clip experiment
    try {
        const googleContactsExperimentCookie = getCookie<string>(cookies, ANDROID_SAVE_TO_CONTACTS);
        if (googleContactsExperimentCookie) {
            analytics.set("experiment_name_2", ANDROID_SAVE_TO_CONTACTS);
            analytics.set("experiment_variant_2", googleContactsExperimentCookie);
        }
    } catch {
        console.error("Error parsing App Clip experiment cookie");
    }

    try {
        const cookieValue = getCookie<string>(cookies, QR_LINK_SUBDOMAINS_FLAG_KEY);
        if (cookieValue) {
            analytics.set("experiment_name_1", QR_LINK_SUBDOMAINS_FLAG_KEY);
            analytics.set("experiment_variant_1", cookieValue);
        }
    } catch {
        console.error("Error parsing App Clip Download App experiment cookie");
    }

    let experienceType: "ios" | "android" | "desktop" | "mobile_fallback" | "unknown" = "unknown";
    switch (true) {
        case !isMobile(): {
            experienceType = "desktop";
            break;
        }
        case isBlacklistedBrowserType(): {
            experienceType = "mobile_fallback";
            break;
        }
        case isIOS(): {
            experienceType = "ios";
            break;
        }
        case isAndroid(): {
            experienceType = "android";
            break;
        }
    }
    analytics.set("experience_id", experienceType);
    const isIncognito = await checkIncognito();
    analytics.set("is_incognito", isIncognito.toString());

    const url = new URL(window.location.href);
    const bs = url.searchParams.get("bs");
    if (bs) analytics.set("blinq_source", bs);

    analytics.page({
        name: "page_view",
        properties: {
            title: "card",
            page_id: "card",
        } as AnalyticsPagePropertiesInput,
    });
}

/**
 * Populate or modify any parts of the DOM that depend on the environment or card data and shouldn't
 * change from here on.
 * @param cardData The current users card data
 * @returns The user ID of the session
 */
async function setup(cardData: Card) {
    // run this in the background
    blinqApiGraphQLClient
        .refreshContinuitySession()
        .then((result) => {
            const userId = result?.refreshContinuitySession?.user_id;
            if (!userId) {
                return;
            }

            analytics.set("user_id", userId);

            window.DD_LOGS?.onReady(() => {
                window.DD_LOGS?.setGlobalContextProperty("user_id", userId);
            });
        })
        .catch((error) => console.error("Error refreshing continuity session", { error }));

    initialiseShareDetailsFormData();

    // Setup experiment tracking
    // const cookies = document.cookie;
    // const blinqExperimentCookie = getCookie<ExperimentIdentifier>(cookies, CARD_PAGE_EXPERIMENT_ID_KEY);
    // if (blinqExperimentCookie) {
    //     analytics.set("experiment_variant_id", blinqExperimentCookie, { scope: "page" });
    // }

    // const shareDetailsModalHeaderEl = document.getElementById("share-contact-info-modal-header");
    const getBlinqModalBodyEl = document.getElementById("modal-get-blinq-body");
    const saveContactModalEl = document.getElementById("save-contact-modal") as HTMLElement;
    const saveContactModalHeaderEl = document.getElementById(
        "save-contact-modal-header"
    ) as HTMLHeadingElement;

    const bySMSRadioBtn = document.getElementById("send-via-sms-radio-btn") as HTMLInputElement | undefined;
    const byEmailRadioBtn = document.getElementById("send-via-email-radio-btn") as
        | HTMLInputElement
        | undefined;
    const addToContactsRadioBtn = document.getElementById("add-to-contacts-radio-btn") as
        | HTMLInputElement
        | undefined;

    const saveContactToPhoneBtn = document.getElementById("save-contact-to-phone-btn");
    const bySMSForm = document.getElementById("send-via-sms-form");
    const byEmailForm = document.getElementById("send-via-email-form");

    if (
        (isIOS() && ((await isSafari()) || isChromeIOS())) ||
        (isAndroid() && (isSamsungBrowser() || (await isBraveBrowser())))
    ) {
        // iOS 15 has the nav bar down the bottom, which can be in a minimized or open mode. When it's
        // open safari does not make the viewport smaller, so vh units still assume that it's minimized,
        // which can push content off the screen. Chrome on iOS also seems to not calculate vh correctly...
        // I hate Apple.
        // Updated: should revise this approach to use the units that are relative to the viewport size `dvh`
        document.documentElement.style.setProperty("--safe-modal-height", "84vh");
    } else if (isAndroid()) {
        document.documentElement.style.setProperty("--safe-modal-height", "88vh");
    } else {
        document.documentElement.style.setProperty("--safe-modal-height", "92vh");
    }

    /*
        Samsung Internet Browser doesn't play nicely with `position: sticky`.

        If we detect Samsung Internet Browser then we fallback to setting
        `position: fixed` on the floating Save Contact button instead of
        the default `position: sticky` styling.

        We also add a bit of bottom margin to the card so the Save Contact button
        doesn't obscure the elements at the bottom of a card.
    */
    if (isSamsungBrowser()) {
        const saveBtnContainerEl = document.querySelector(".save-btn-container");
        const cardEl = document.querySelector(".blinq-card");
        if (saveBtnContainerEl && cardEl) {
            saveBtnContainerEl.classList.add("save-btn-container-sib-override");
            cardEl.classList.add("blinq-card-sib-override");
        }
    }

    // DuckDuckGo on Android doesn't include the navigation bar in it's height calculations so everything
    // can get a bit pushed off the screen when it's being shown.
    if (isDuckDuckGoBrowser() && isAndroid()) {
        document.documentElement.style.setProperty("--bottom-safety-height", "2.5rem");
    }

    const modalInputEls = document.querySelectorAll(".modal input");

    // Android doesn't scroll to focused input elements in the modal properly, so we need to overwrite the
    // behaviour. I found smooth scrolling could be a bit janky on android so I only do smooth scrolling on ios or mac.
    modalInputEls.forEach((el) => {
        el.addEventListener("focus", (event: Event) => {
            if (event.target instanceof HTMLInputElement) {
                const scrollableEl = event.target.closest(".modal-scrollable-content");
                const inputFieldEl = event.target.closest(".input-field");

                if (scrollableEl && inputFieldEl instanceof HTMLFieldSetElement) {
                    const topPos = inputFieldEl.offsetTop;
                    if (isIOS() || isMac()) {
                        anime({
                            targets: scrollableEl,
                            scrollTop: topPos - 24,
                            duration: 300,
                            easing: "easeInOutQuad",
                        });
                    } else if (isAndroid()) {
                        // On android there is the focus bug where the virtual keyboard will sometimes appear after we set the scrollTop, thus
                        // the scrollTop won't have any effect as the scrollable parent element is not overflowing yet. We need to
                        // wait for a period of time before setting the scrollTop, 250ms will work for most cases.
                        setTimeout(() => {
                            scrollableEl.scrollTop = topPos - 24;
                        }, 250);
                    } else {
                        scrollableEl.scrollTop = topPos - 24;
                    }
                }
            }
        });
    });

    if (getBlinqModalBodyEl) {
        getBlinqModalBodyEl.innerHTML = cardData.firstName?.trim()
            ? `<span>Get Blinq to <em>create your own </em> digital business card & always have <b>${cardData.firstName?.trim()}</b>'s updated contact details</span>`
            : `<span>Get Blinq to <em>create your own </em> digital business card</span>`;
    }
    const isShareViaSMSEnabled = !!bySMSRadioBtn;
    const sliderAnimation = anime({
        targets: ".slider",
        scaleX: [
            { value: 1.15, duration: 200, delay: 50, easing: "easeOutSine" },
            { value: 1, duration: 300 },
        ],
        easing: "easeOutElastic(1, .6)",
        autoplay: false,
    });

    const saveContactHeadingHtml = `<span>Save <b>${cardData.firstName?.trim()}</b>'s contact information</span>`;
    const smsContactHeadingText = `<span>Text <b>${cardData.firstName?.trim()}</b>'s details to yourself</span>`;
    const emailContactHeadingText = `<span>Enter your email to receive contact</span>`;
    saveContactModalHeaderEl.innerHTML = saveContactHeadingHtml;
    // Add event listeners to the radio buttons
    // TODO: Make the radio inputs into buttons with the tab role and make fully accessible
    addToContactsRadioBtn?.addEventListener("click", () => {
        sliderAnimation.play();
        playHapticFeedback().touch();
        saveContactModalHeaderEl.innerHTML = saveContactHeadingHtml;
        saveContactModalEl?.setAttribute("data-tab", "save");
        bySMSForm?.classList.remove("visible");
        byEmailForm?.classList.remove("visible");
        saveContactToPhoneBtn?.classList.add("visible");
        showRecommendationForFirefoxBraveUserOnIOS();
    });

    bySMSRadioBtn?.addEventListener("click", () => {
        sliderAnimation.play();
        playHapticFeedback().touch();
        saveContactModalHeaderEl.innerHTML = smsContactHeadingText;
        saveContactModalEl?.setAttribute("data-tab", "sms");
        saveContactToPhoneBtn?.classList.remove("visible");
        byEmailForm?.classList.remove("visible");
        bySMSForm?.classList.add("visible");
        hideRecommendationForFirefoxBraveUserOnIOS();
    });
    byEmailRadioBtn?.addEventListener("click", () => {
        sliderAnimation.play();
        playHapticFeedback().touch();
        saveContactModalHeaderEl.innerHTML = emailContactHeadingText;
        saveContactModalEl?.setAttribute("data-tab", "email");
        saveContactToPhoneBtn?.classList.remove("visible");
        bySMSForm?.classList.remove("visible");
        byEmailForm?.classList.add("visible");
        hideRecommendationForFirefoxBraveUserOnIOS();
    });

    saveContactModalHeaderEl.addEventListener("load", () => {
        sliderAnimation.play();
        playHapticFeedback().touch();
        saveContactModalHeaderEl.innerHTML = emailContactHeadingText;
        saveContactModalEl?.setAttribute("data-tab", "email");
        saveContactToPhoneBtn?.classList.remove("visible");
        bySMSForm?.classList.remove("visible");
        byEmailForm?.classList.add("visible");
        hideRecommendationForFirefoxBraveUserOnIOS();
    });

    saveContactModalEl?.setAttribute("data-tab", "email");
    saveContactModalEl?.setAttribute("data-default-tab", "email");
    await setupRecommendationForBraveFirefoxOnIOS(bySMSRadioBtn, byEmailRadioBtn);

    if ((await isBraveBrowser()) && isIOS()) {
        setupGetBlinqForBraveOnIOS();
    }

    /**
     * If the browser is known to have problems downloading a vcard
     * OR
     * If we are on a desktop
     * then hide the download section
     */
    if (await shouldWeHideDownloadContactTab()) {
        // hide download contact button and radio option
        hideDownloadContactButtonFromSaveContactModal(emailContactHeadingText);
    }

    if (!isShareViaSMSEnabled) {
        saveContactToPhoneBtn?.classList.remove("visible");
        (document.querySelector("#add-to-contacts-radio-btn + label") as HTMLElement).style.display = "none";
        byEmailRadioBtn?.classList.remove("visible");
        (document.querySelector("#send-via-email-radio-btn + label") as HTMLElement).style.display = "none";
        byEmailForm?.classList.add("visible");

        saveContactModalHeaderEl.innerHTML = emailContactHeadingText;
    }

    const weShowIOSSaveContactExplainer = await shouldWeShowIOSExplainerModal();
    const weShowAndroidSaveContactExplainer = shouldWeShowAndroidExplainerModal();
    const goBackButtonEl = saveContactModalEl?.querySelector(".modal-back") as HTMLButtonElement | null;
    if (goBackButtonEl && !weShowIOSSaveContactExplainer && !weShowAndroidSaveContactExplainer) {
        goBackButtonEl.style.display = "none";
    }

    // setup send contact by email input event
    const sendViaEmailInput = document.getElementById("send-via-email-input") as HTMLInputElement;
    const sendViaEmailFormButton = document.querySelector("form#send-via-email-form button[type='submit']");
    sendViaEmailInput?.addEventListener("input", (e) => {
        const inputValue = (e?.target as HTMLInputElement)?.value;
        if (inputValue?.length > 0) {
            sendViaEmailFormButton?.removeAttribute("disabled");
        } else {
            sendViaEmailFormButton?.setAttribute("disabled", "true");
        }
    });

    // setup send contact by sms input event
    const sendViaSMSInput = document.getElementById("send-via-sms-input");
    const sendViaSMSFormButton = document.querySelector("form#send-via-sms-form button[type='submit']");
    if (sendViaSMSInput) {
        sendViaSMSInput.addEventListener("input", (e: Event) => {
            const inputValue = (e?.target as HTMLInputElement)?.value.replace(/\s+/g, "");

            if (inputValue?.length > 0) {
                sendViaSMSFormButton?.removeAttribute("disabled");
            } else {
                sendViaSMSFormButton?.setAttribute("disabled", "true");
            }
        });
    } else {
        console.warn("send-via-sms-input is missing");
    }

    if (cardData.autoExpandExchangeInfoForm) {
        expandExchangeInfoForm();
    }

    // Input/ui setup
    setupCardWorkerExperiments();
    setupProfilePictures(cardData.profilePictureImg?.editedImageUrl);
    setupShareContactDetailsBackModalHeader(cardData.profilePictureImg?.editedImageUrl, cardData.firstName);
    setupPhoneNumberInput("send-via-sms-input", "select-country-input");
    setupCountryCodeSelector();
    setupCongratulationGetBlinqAnimation();
    setupIOSExplainerModal();
    setupAndroidExplainerOverlayAnimation();
    prefillShareDetails();
    skipToStep(cardData);
    shoudDisplaySaveContactToast();

    // TODO maybe refactor this.
    // Display back the google save contact modal when user deny the google auth
    shouldDisplaySaveContactViaEmailInsteadModal();
    shouldSaveGoogleContactInBackground();
}

function shouldSaveGoogleContactInBackground() {
    if (!shouldWeShowAndroidExplainerModal()) return;

    const step = getCookie<string>(document.cookie, "step");
    if (step === "save_google_contact") {
        const btn = document.getElementById("google-save-contact-btn");
        if (btn == null) return;
        showAndroidExplainerModalWithDefaultBehavior({ googleSaveLoading: true });
        handleGoogleSaveContactBtnClick(btn);
    }
}

function shouldDisplaySaveContactViaEmailInsteadModal() {
    const ERROR_PARAM = "error";
    if (!shouldWeShowAndroidExplainerModal()) return;

    const errorCode = getParamAndRemove(ERROR_PARAM);

    if (errorCode) {
        if (errorCode === "access_denied") {
            analytics.track({
                name: "contact_saved_cancelled",
                properties: { save_card_method: "google_contacts_api" },
            });
        }
        displaySaveContactToast("error", true, errorCode);
        showSaveContactViaEmailInsteadModal(showAndroidExplainerModalWithDefaultBehavior);
    }
}

/**
 * Skips to a certain step or modal in a flow when the card is first rendered.
 * If the card worker window URL has a query parameter `step` then it will skip to that step.
 *
 * Supported values:
 * - `share` - Skips to the share contact details modal
 */
function skipToStep(cardData: Card) {
    const paramToCheck = "step";
    const onGoBack = () => {
        return;
    };

    const step = getParamAndRemove(paramToCheck);

    switch (step) {
        case "share":
            startSendContactBackFlow(cardData.displayGetBlinqPrompt ?? false, onGoBack, true);
            break;
        // could add more for testing?
        default:
            return;
    }
}

function getParamAndRemove(param: string) {
    const url = new URL(window.location.href);
    const params = new URLSearchParams(url.search);
    const value = params.get(param);

    if (params.has(param)) {
        url.searchParams.delete(param);
        window.history.replaceState({}, "", url.toString());
    }

    return value;
}
/**
 * currently use for App Clips only, after they save the contact, we will check for the query param `saved_contact`
 * to display the toast and track the event
 */
function shoudDisplaySaveContactToast() {
    const savedContactStatus = getParamAndRemove("saved_contact");
    if (savedContactStatus === "done") {
        trackAppClipSaveContact();
    }
    switch (savedContactStatus) {
        case "error":
        case "done":
            displaySaveContactToast(
                savedContactStatus,
                isAndroid(),
                getParamAndRemove("saved_contact_error") ?? ""
            );
            break;
        default:
            return;
    }
}

function trackAppClipSaveContact() {
    analytics.track({
        name: "contact_saved_done",
        properties: { save_card_method: "ios_app_clip" },
    });
    storeInstance.saveContactMethod = "ios_app_clip";
}

/**
 * Brave on iOS calculates the viewport wrong so we need a special treatment to prevent the
 * overflowing issue
 */
function setupGetBlinqForBraveOnIOS() {
    const getBlinqModalEl = document.getElementById("get-blinq-modal") as HTMLElement;
    getBlinqModalEl.classList.add("for-ios-brave");
}

/**
 * Brave and Firefox do not automatically add contact details to phone on iOS so
 * we will display a recommendation message for the user to use sms or email instead
 */
async function setupRecommendationForBraveFirefoxOnIOS(
    bySMSRadioBtn: HTMLInputElement | undefined,
    byEmailRadioBtn: HTMLInputElement | undefined
) {
    const recommendationStatementEl = document.getElementById(
        "recommendation-for-brave-and-firefox-on-ios"
    ) as HTMLElement;
    if (await shouldWeAllowDownloadButNotShowExplainerOnIOS()) {
        const anchorButtons = recommendationStatementEl.querySelectorAll(
            ".blinq-button"
        ) as NodeListOf<HTMLButtonElement>;
        anchorButtons[0].addEventListener("click", () => byEmailRadioBtn?.click());
        // may have the sms option disabled
        if (anchorButtons.length > 1) {
            anchorButtons[1].addEventListener("click", () => bySMSRadioBtn?.click());
        }
    } else {
        recommendationStatementEl.style.display = "none";
    }
}

/** This functions makes surgical changes to the card worker UI
 * depending on which EXPERIMENT flags are set to `true`
 * */
function setupCardWorkerExperiments() {
    // TO DO - call zaraz set with experiment IDs
    if (EXPERIMENT_1_FLAG) {
        const shareContactInfoModalCloseButton = document.getElementById(
            "share-contact-info-modal-close-button"
        );

        const shareContactInfoModalSkipButton = document.getElementById(
            "share-contact-info-modal-skip-button"
        );

        if (shareContactInfoModalCloseButton) {
            shareContactInfoModalCloseButton.style.display = "none";
        }

        if (shareContactInfoModalSkipButton) {
            shareContactInfoModalSkipButton.style.display = "flex";
        }
    }
}

/**
 * If the user has a profile image, display it, else hide it
 */
function setupProfilePictures(profilePictureUrl: string | undefined) {
    const profileImageEls = document.querySelectorAll(".modal-profile-pic") as NodeListOf<HTMLImageElement>;
    profileImageEls.forEach((imgEl) => {
        if (profilePictureUrl) {
            imgEl.src = profilePictureUrl;
            imgEl.alt = `${cardData.firstName?.trim()}'s profile picture`;
        } else {
            imgEl.style.display = "none";
        }
    });
}

/**
 * Set up the header for the share contact details back modal, this includes
 * setting up the profile image and the copy text. If the user does not have
 * a profile image, use the silhouette instead
 */
function setupShareContactDetailsBackModalHeader(
    profilePictureUrl: string | undefined,
    firstName: string | undefined
) {
    const shareDetailsModalHeaderEl = document.getElementById("share-contact-info-modal-header");
    if (!shareDetailsModalHeaderEl) {
        return;
    }

    if (profilePictureUrl) {
        const userSilhouette = shareDetailsModalHeaderEl.querySelector<HTMLElement>(".airplane-silhouette");
        if (userSilhouette) {
            userSilhouette.style.display = "none";
        }
    }

    if (!profilePictureUrl) {
        const floatingAirplaneIcon =
            shareDetailsModalHeaderEl.querySelector<HTMLElement>(".floating-paper-plane");
        if (floatingAirplaneIcon) {
            floatingAirplaneIcon.style.display = "none";
        }
    }

    const shareContactInfoMessageHtml = firstName
        ? `<p>Share your contact information with <strong>${firstName}</strong></p>`
        : `<p>Send your contact information to this person<p>`;
    const messageContainerEl = shareDetailsModalHeaderEl.querySelector<HTMLElement>(
        ".share-details-message-container"
    );
    if (messageContainerEl) {
        messageContainerEl.innerHTML = shareContactInfoMessageHtml;
    }
}

/**
 * Sets up the Lottie animation for iOS that tries to dissuade people from pressing "Done" when the modal appears
 */
function setupIOSExplainerModal() {
    const explainerAnimation = document.querySelector(".ios-save-contact-explainer-animation");
    if (explainerAnimation) {
        const animation = Lottie.loadAnimation({
            name: IOS_SAVE_CONTACT_EXPLAINER_ANIMATION_NAME,
            container: explainerAnimation,
            renderer: "svg",
            loop: false,
            autoplay: false,
            path: "ios-save-contact-explainer.json",
        });
        saveLottieAnimationInstance(IOS_SAVE_CONTACT_EXPLAINER_ANIMATION_NAME, animation);
    }
}

/**
 * Set up the overlay animation for the Android explainer modal, we need to use anime.js instead
 * of raw css because we want to sync the animations together.
 *
 */
function setupAndroidExplainerOverlayAnimation() {
    const androidsExplainerModal = document.getElementById(
        "save-contact-android-explainer-modal"
    ) as HTMLElement;
    const topArrow = androidsExplainerModal.querySelector(".top-pointer") as HTMLElement;
    const bottomArrow = androidsExplainerModal.querySelector(".bottom-pointer") as HTMLElement;

    // different timelines to make sure the animations start at the same time and are in sync
    const animationTimelineForLeftArrow = anime.timeline({ loop: true });
    const animationTimelineForRightArrow = anime.timeline({ loop: true });

    animationTimelineForLeftArrow.add({
        targets: topArrow,
        keyframes: [
            { translateY: 8, duration: 450 },
            { translateY: 0, duration: 450 },
        ],
        easing: "easeInOutSine",
    });

    animationTimelineForRightArrow.add({
        targets: bottomArrow,
        keyframes: [
            { translateY: -8, duration: 450 },
            { translateY: 0, duration: 450 },
        ],
        easing: "easeInOutSine",
    });
}

/**
 * Set up lottie animation for the get blinq modal
 *
 */
function setupCongratulationGetBlinqAnimation() {
    const congratsAnimation = document.querySelector(".get-blinq-animation");
    if (congratsAnimation) {
        const animation = Lottie.loadAnimation({
            name: CONGRATULATION_GET_BLINQ_ANIMATION_NAME,
            container: congratsAnimation,
            renderer: "svg",
            loop: false,
            autoplay: false,
            path: "congratulations-get-blinq-animation.json",
        });
        saveLottieAnimationInstance(CONGRATULATION_GET_BLINQ_ANIMATION_NAME, animation);
    }

    const getBlinqAnimationStage1 = document.getElementById("get-blinq-multistage-animation-stage-1");
    const getBlinqAnimationStage2 = document.getElementById("get-blinq-multistage-animation-stage-2");
    if (!getBlinqAnimationStage1 || !getBlinqAnimationStage2) {
        return;
    }
    const getBlinqStage1Animation = Lottie.loadAnimation({
        name: GET_BLINQ_STAGE_1_ANIMATION_NAME,
        container: getBlinqAnimationStage1,
        renderer: "svg",
        loop: false,
        autoplay: false,
        path: "get-blinq-animation-stage-1.json",
    });
    saveLottieAnimationInstance(GET_BLINQ_STAGE_1_ANIMATION_NAME, getBlinqStage1Animation);

    const getBlinqStage2Animation = Lottie.loadAnimation({
        name: GET_BLINQ_STAGE_2_ANIMATION_NAME,
        container: getBlinqAnimationStage2,
        renderer: "svg",
        loop: false,
        autoplay: false,
        path: "get-blinq-animation-stage-2.json",
    });
    saveLottieAnimationInstance(GET_BLINQ_STAGE_2_ANIMATION_NAME, getBlinqStage2Animation);
}

function setupPhoneNumberInput(elId: string, countryInputId: string) {
    const phoneNumberInputEl = document.getElementById(elId);

    let prevValue = "";

    phoneNumberInputEl?.addEventListener("input", (event) => {
        const countryInput = document.getElementById(countryInputId) as HTMLInputElement | undefined;
        const countryCode = countryInput?.value as CountryCode | undefined;
        if (!(event.target instanceof HTMLInputElement)) return;

        const newValue = event.target.value;

        let formattedPhoneNumber = formatIncompletePhoneNumber(newValue, countryCode);

        // By default, if a value is something like `"(123)"` then Backspace would only erase the rightmost brace
        // becoming something like `"(123"` which would give the same `"123"` value which would then be formatted back to `"(123)"`
        // and so a user wouldn't be able to erase the phone number. This fixes that issue.
        if (formattedPhoneNumber === prevValue) {
            const digits = formattedPhoneNumber.replace(/\D/g, ""); //strip away non-numeric characters
            const withoutLastDigit = digits.slice(0, digits.length - 1); //remove the last digit
            formattedPhoneNumber = formatIncompletePhoneNumber(withoutLastDigit, countryCode);
        }

        event.target.value = formattedPhoneNumber;
        prevValue = formattedPhoneNumber;
    });
}

function setupCountryCodeSelector() {
    const selectCountryFlagDisplay = document.getElementById("select-country-flag-display");
    const selectCountryCodeDisplay = document.getElementById(
        "select-country-code-display"
    ) as HTMLSpanElement;
    const selectCountryInput = document.getElementById("select-country-input") as HTMLInputElement;
    const countrySelectorList = document.querySelector("#select-country-modal ul");

    countrySelectorList?.addEventListener("click", (event) => {
        event.stopPropagation();
        const nextSelectedCountry = (event?.target as HTMLElement).closest("li")?.dataset?.value;

        selectCountryFlagDisplay?.classList.remove(`fflag-${selectCountryInput.value}`);
        selectCountryFlagDisplay?.classList.add(`fflag-${nextSelectedCountry}`);
        selectCountryCodeDisplay.innerText = `+${getCountryCallingCode(
            nextSelectedCountry?.toUpperCase() as CountryCode
        )}`;
        selectCountryInput.value = nextSelectedCountry ?? "";

        closeSelectCountryModal();
    });
}

let saveDetailsFormData: ShareDetailsFormData = {
    first_name: "",
    last_name: "",
    email: "",
    job_title: "",
    org_name: "",
    phone_number: "",
};

export function initialiseShareDetailsFormData() {
    const cachedSaveDetailsFormData = localStorage.getItem(SAVE_DETAILS_FORM_CACHE_KEY);
    if (cachedSaveDetailsFormData) {
        saveDetailsFormData = JSON.parse(cachedSaveDetailsFormData);
    }
}

export function persistShareDetailsFormData(formData: ShareDetailsFormData) {
    saveDetailsFormData = formData;
    localStorage.setItem(SAVE_DETAILS_FORM_CACHE_KEY, JSON.stringify(formData));
}

export function getShareDetailsFormData(): ShareDetailsFormData {
    return saveDetailsFormData;
}

// Events
export * from "./events/explainer";
export * from "./events/get-blinq";
export * from "./events/save-contact";
export * from "./events/send-contact";
export * from "./events/ui";

// Forms
export * from "./events/form";

// App Clip events
export * from "./events/app-clip";
