import { DEEPLINK_BASE_URL } from "../env";
import { hasUnicode } from "./utils";

/** Params to attach to a session continuity link */
type LinkQueryParam =
    | "_dl" // a hint to the app on what to do with the link
    | "cardId" // the card ID of the card to be opened / added as a contact
    | "cardUpdateId" // the update ID of the caed to be opened / added as a contact
    | "d" // the full date of the meeting
    | "dd" // the date of the meeting
    | "dm" // the month of the meeting
    | "dy" // the year of the meeting
    | "email" // the email entered by the user in the form
    | "firstName" // the first name entered by the user in the form
    | "jobTitle" // the job title entered by the user in the form
    | "l" // the location of the exchange
    | "lastName" // the last name entered by the user in the form
    | "n" // the first name of the card that referred this user
    | "opaque_token" // can be exchanged for a token via the exchangeOneTimeLoginToken mutation
    | "orgId" // the org ID of the card that referred this user
    | "orgName" // the org name entered by the user in the form
    | "phoneNumber" // the phone number entered by the user in the form
    | "ref_card_id" // the card ID of the card that referred this user
    | "ref_card_org_id" // the org ID of the card that referred this user
    | "ref_card_update_id" // the update ID of the card that referred this user
    | "ref_card_user_id" // the user ID of the card that referred this user
    | "ref_email_signature_id" // the email signature ID of the card that referred this user
    | "ref_has_filled_share_details_form" // whether the user has filled the share details form
    | "ref_session_id" // the session ID of the session that referred this user
    | "ref_viewer_id" // the viewer ID of the session that referred this user
    | "bs" // the source of the card that referred this user
    | "userId"; // the user ID of the card that referred this user

/** These are attached to the deeplink itself */
type DeepQueryParam = "apn" | "isi" | "ibi" | "imv" | "amv" | "efr";

/** Builds a link */
export class ReferralDeeplinkBuilder {
    private deepUrl: URL;
    private linkUrl: URL;
    /** @param link: The link the deeplink points to */
    constructor(link: string) {
        this.deepUrl = new URL(DEEPLINK_BASE_URL ? DEEPLINK_BASE_URL : `https://blinqapp.page.link`);
        this.linkUrl = new URL(link);
    }

    /** Adds a key-value pair to the query string, is the calue is valid
     * @returns this ReferralDeeplinkBuilder instance
     */
    addLinkQueryParam(paramName: LinkQueryParam, paramValue?: string) {
        if (paramValue && isValidUrlParam(paramValue) && paramValue !== undefined && paramValue !== null) {
            this.linkUrl.searchParams.set(paramName, paramValue);
        }

        return this;
    }

    /** Adds a key-value pair to the query string, is the calue is valid
     * @returns this ReferralDeeplinkBuilder instance
     */
    addDeepQueryParam(paramName: DeepQueryParam, paramValue?: string) {
        if (paramValue && isValidUrlParam(paramValue) && paramValue !== undefined && paramValue !== null) {
            this.deepUrl.searchParams.set(paramName, paramValue);
        }

        return this;
    }

    public build() {
        this.deepUrl.searchParams.append("link", this.linkUrl.toString());
        return this.deepUrl.toString();
    }
}

/**
 * Checks if the supplied paramater contains non-unicode characters (in which case it can't be used
 * in the URL). This is because dynamic links can't be decoded on mobile if they contain non-unicode
 * characters
 * @param param The value needing to be verified
 */
function isValidUrlParam(param: string) {
    return param && !hasUnicode(param);
}
