export const CONGRATULATION_GET_BLINQ_ANIMATION_NAME = "CONGRATULATION_GET_BLINQ_ANIMATION_NAME";
export const GET_BLINQ_STAGE_1_ANIMATION_NAME = "GET_BLINQ_STAGE_1_ANIMATION_NAME";
export const GET_BLINQ_STAGE_2_ANIMATION_NAME = "GET_BLINQ_STAGE_2_ANIMATION_NAME";

export const IOS_SAVE_CONTACT_EXPLAINER_ANIMATION_NAME = "IOS_SAVE_CONTACT_EXPLAINER_ANIMATION_NAME";

export const APP_CLIP_LOADING_ANIMATION_NAME = "APP_CLIP_LOADING_ANIMATION_NAME";

export const SUCCESS_BUTTON_COLOUR = "hsla(148, 59%, 47%, 1)";

export const EXPLAINER_ANIMATION_LOOP_DELAY_MS = 300;

/** This is the name of the cookie we use to store the user id of the card page visitor */
export const CARD_PAGE_VIEWER_ID_KEY = "blinq_viewer_id";

/** This is the name of the cookie we use to store the experiment variant identifier of the card page visitor */
export const CARD_PAGE_EXPERIMENT_ID_KEY = "blinq_experiment_card_worker";

export const SAVE_DETAILS_FORM_CACHE_KEY = "blinq_save_details";

export const GOOGLE_PEOPLE_AUTH_COOKIE = "blnq_gpa";

export const OPAQUE_TOKEN_KEY = "blinq.opaque_token";
/**
 * This is the key of the query string to indicate whether the person who shares the card
 * wants the recipient to share the their details back.
 * */
export const ALLOW_RECIPROCAL_QUERY_KEY = "allow_reciprocal";

/** Save Contact Flow - Experiment 1 flag
 *
 * Under EXPERIMENT_1_FLAG, we replace the 'x' close button situated in the top-right of the
 * Share contact details modal with a text button which reads 'Skip'.
 */
export const EXPERIMENT_1_FLAG = true;

/** Save Contact Flow - Experiment 2 flag
 *
 * Under EXPERIMENT_2_FLAG, when a user taps the 'Save contact' button on a mobile device,
 * we skip the explainer modal that normally displays and download the contact to the device
 * immediately.
 *
 * This flag is also used to hide the 'x' close button in the Share Contact Details
 * modal on mobile devices. This is because there's no preceding Explainer Modal
 * to go back to when the flag is `true`.
 */
export const EXPERIMENT_2_FLAG = false;

/**
 * Adaptive Experiment Cookie Key
 *
 * In this experiment, we will be testing the effectiveness of the adaptive experiment
 * where we have two action buttons, one for saving the contact and the other for sharing
 * details back to the card owner. The experiment also includes a new tutorial overlay for iOS
 * users.
 */
export const ADAPTIVE_EXPERIMENT_COOKIE_KEY = "blinq_adaptive_sharing_experiment_v4";

/**
 * Personal Preview Experiment Cookie Key
 *
 * In this experiment, we will be testing showing a preview of a user's card on the get blinq app screen
 */
export const PREVIEW_CARD_FLAG_KEY = "card_worker_personalized_preview";

export const APP_CLIP_DOWNLOAD_APP_FLAG_KEY = "ios_download_app_v2";

/**
 * QR Link Subdomains Experiment Cookie Key
 *
 * Used to switch between QR link domains to support AASA file updates. Allows us to rollout invocation changes gradually, or rollback quickly.
 * More info see: https://www.notion.so/blinq-me/AASA-Files-Problem-Solution-0c31c86de059420cad5c21b3e78852be?pvs=4
 */
export const QR_LINK_SUBDOMAINS_FLAG_KEY = "qr_link_subdomains";

/**
 * In this experiment, we will replace the Save to Contacts button with Save to Google Contacts button
 * for more information, see https://www.notion.so/blinq-me/Save-to-Google-Contacts-f8db7b721f484cfe92d406ba2c3f0eaf?pvs=4
 * v2: iteration on insufficient permission
 */
export const ANDROID_SAVE_TO_CONTACTS = "cw_android_save_to_contacts_v1";

export const DATA_FLOWS = {
    SWAP_DETAILS: "SWAP_DETAILS",
} as const;

export type DATA_FLOW = (typeof DATA_FLOWS)[keyof typeof DATA_FLOWS];
