import { BlinqApiGraphQLClient } from "common/utils/blinq-api-graphql-client";
import { API_BASE_URL } from "../env";

export async function postData(url = "", data = {}) {
    try {
        // Default options are marked with *
        const response = await fetch(url, {
            method: "POST",
            //mode: "cors", // no-cors, *cors, same-origin
            //credentials: "include",
            headers: {
                "Content-Type": "application/json",
                // 'Content-Type': 'application/x-www-form-urlencoded',
            },
            redirect: "follow", // manual, *follow, error
            referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
            body: JSON.stringify(data), // body data type must match "Content-Type" header
        });
        // check the response was OK.
        // the response may not be JSON, so do not attempt to parse JSON
        if (!response.ok) {
            console.warn(`POST to ${url} failed with status ${response.statusText}`);
        }
    } catch (err) {
        console.error(err);
        return;
    }
}

export const blinqApiGraphQLClient = new BlinqApiGraphQLClient(API_BASE_URL);
