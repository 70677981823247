import Modal from "./modal";

export interface StaticBaseComponent {
    getInstance: (el: HTMLElement & { M_Modal?: Modal }) => Modal | undefined;
}

export interface BaseComponent {
    destroy: () => void;
}

class Component {
    /**
     * Generic constructor for all components
     * @constructor
     * @param {Element} el
     */
    constructor(classDef: StaticBaseComponent, el: HTMLElement & { M_Modal?: Modal }) {
        // Display error if el is valid HTML Element
        if (!(el instanceof Element)) {
            console.error(Error(el + " is not an HTML Element"));
        }

        // If exists, destroy and reinitialize in child
        const ins = classDef.getInstance(el);
        if (ins) {
            ins.destroy();
        }
    }
}

export default Component;
