var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div \n    class=\"blinq-text-field\" \n    id=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":3,"column":8},"end":{"line":3,"column":14}}}) : helper)))
    + "\" \n    data-validate-group=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"dataValidationGroup") || (depth0 != null ? lookupProperty(depth0,"dataValidationGroup") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"dataValidationGroup","hash":{},"data":data,"loc":{"start":{"line":4,"column":25},"end":{"line":4,"column":48}}}) : helper)))
    + "\"\n>\n    <input \n        id=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"inputId") || (depth0 != null ? lookupProperty(depth0,"inputId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"inputId","hash":{},"data":data,"loc":{"start":{"line":7,"column":12},"end":{"line":7,"column":23}}}) : helper)))
    + "\"\n        class=\"text-field-input\"  \n        name=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"inputName") || (depth0 != null ? lookupProperty(depth0,"inputName") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"inputName","hash":{},"data":data,"loc":{"start":{"line":9,"column":14},"end":{"line":9,"column":27}}}) : helper)))
    + "\"\n        type=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"inputType") || (depth0 != null ? lookupProperty(depth0,"inputType") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"inputType","hash":{},"data":data,"loc":{"start":{"line":10,"column":14},"end":{"line":10,"column":27}}}) : helper)))
    + "\"\n        placeholder=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"inputPlaceholder") || (depth0 != null ? lookupProperty(depth0,"inputPlaceholder") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"inputPlaceholder","hash":{},"data":data,"loc":{"start":{"line":11,"column":21},"end":{"line":11,"column":41}}}) : helper)))
    + "\"\n        autocomplete=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"inputAutocomplete") || (depth0 != null ? lookupProperty(depth0,"inputAutocomplete") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"inputAutocomplete","hash":{},"data":data,"loc":{"start":{"line":12,"column":22},"end":{"line":12,"column":43}}}) : helper)))
    + "\"\n    />\n    <fieldset class=\"text-fieldset\">\n        <legend class=\"text-fieldset-legend\">\n            <label class=\"text-field-label\" for=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"inputId") || (depth0 != null ? lookupProperty(depth0,"inputId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"inputId","hash":{},"data":data,"loc":{"start":{"line":16,"column":49},"end":{"line":16,"column":60}}}) : helper)))
    + "\">\n                "
    + alias4(((helper = (helper = lookupProperty(helpers,"inputPlaceholder") || (depth0 != null ? lookupProperty(depth0,"inputPlaceholder") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"inputPlaceholder","hash":{},"data":data,"loc":{"start":{"line":17,"column":16},"end":{"line":17,"column":36}}}) : helper)))
    + "\n            </label>\n        </legend>\n    </fieldset>\n    <span class=\"error-text\">\n        "
    + alias4(((helper = (helper = lookupProperty(helpers,"inputErrorText") || (depth0 != null ? lookupProperty(depth0,"inputErrorText") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"inputErrorText","hash":{},"data":data,"loc":{"start":{"line":22,"column":8},"end":{"line":22,"column":26}}}) : helper)))
    + "\n    </span>\n</div>";
},"useData":true});