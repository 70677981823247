import { getCookie } from "common/utils/helpers";
import detectIncognito from "detectincognitojs";
import Lottie from "lottie-web";

import { Modal } from "../../controllers";
import { APP_CLIP_BUNDLE_ID, APP_CLIP_URL } from "../../env";
import { getLottieAnimationInstance, saveLottieAnimationInstance } from "../../modules/animations";
import { isIMessagePreview, isSafari } from "../../modules/platform-detection";
import { analytics } from "../analytics";
import { storeInstance } from "../analytics/store";
import { APP_CLIP_DOWNLOAD_APP_FLAG_KEY, APP_CLIP_LOADING_ANIMATION_NAME } from "../constants";
import { getCardData, getDateOfExchange, getLocationOfExchange } from "../utils";
import { handleNonAcSaveContact } from "./save-contact";
import { showSaveContactViaEmailInsteadModal } from "./ui";

const APP_CLIP_LOADING_ANIMATION_LOOP_DELAY_MS = 900;
const cardData = getCardData();

const getFeatureFlag = (key: string) => {
    return getCookie<string>(document.cookie, key);
};

const getAppClipUrl = (startTime?: string) => {
    const cardData = getCardData();

    const getBsParam = new URLSearchParams(window.location.search).get("bs");

    const { ref_viewer_id } = storeInstance.getReferralData();

    const timeStamp = startTime || Date.now().toString();

    const params = {
        l: getLocationOfExchange(),
        d: encodeURI(getDateOfExchange().full),
        cardid: cardData.cardId,
        bs: getBsParam,
        viewerId: ref_viewer_id,
        ida_v: getFeatureFlag(APP_CLIP_DOWNLOAD_APP_FLAG_KEY), // optional param
        startTime: timeStamp,
    };

    const appClipUrl = new URL(APP_CLIP_URL);

    Object.entries(params)
        .filter(([, value]) => !!value)
        .forEach(([key, value]) => value && appClipUrl.searchParams.append(key, value));

    return appClipUrl.toString();
};

// Elements
const getSaveContactButton = () => {
    return document.getElementById("save-contact-btn");
};

const getAcOpenButton = () => {
    return document.getElementById("app-clip-open-btn");
};

const getACModal = () => {
    return document.getElementById("save-contact-app-clip-modal");
};

const getACModalSkipButton = () => {
    return document.getElementById("connect-in-a-blinq-skip-btn");
};

// Flags
const getIsACEnabled = () => {
    const saveContactButton = getSaveContactButton();
    const app_clip_enabled = saveContactButton
        ? saveContactButton.getAttribute("data-ac-enabled") === "true"
        : false;
    console.debug("app_clip_enabled", app_clip_enabled);
    return app_clip_enabled;
};

// Functions
let showSkip = false;
export const showSkipButton = (setSkip: boolean) => {
    const skipButton = getACModalSkipButton();
    if (skipButton) {
        if (setSkip) {
            setTimeout(() => {
                showSkip = true;
                skipButton.classList.add("enabled");
            }, 500);
        } else if (showSkip) {
            skipButton.classList.add("enabled");
        }
    }
};

// Setup Functions
export function setupACLoadingAnimation() {
    const explainerAnimation = document.querySelector(".app-clip-loading-animation");
    if (explainerAnimation) {
        const animation = Lottie.loadAnimation({
            name: APP_CLIP_LOADING_ANIMATION_NAME,
            container: explainerAnimation,
            renderer: "svg",
            loop: true,
            autoplay: false,
            path: "app-clip-loading-animation.json",
        });
        saveLottieAnimationInstance(APP_CLIP_LOADING_ANIMATION_NAME, animation);
    }
}

const setupACOpenButton = () => {
    const modal = getACModal();
    const openAppClipBtn = getAcOpenButton();
    if (openAppClipBtn && modal) {
        openAppClipBtn.setAttribute("disabled", "true");
        // Set button to black. This is a fix to prevent the button from being white on Safari
        openAppClipBtn.style.setProperty("--card-color", "#000");

        setTimeout(() => {
            openAppClipBtn.removeAttribute("disabled");
            document.getElementById("animate-info-text")?.classList.add("show");
        }, 4000);
    }
};

const setupAC = async () => {
    if (getIsACEnabled() && !isIMessagePreview()) {
        const details = {
            name: "apple-itunes-app",
            content: "app-clip-bundle-id=" + APP_CLIP_BUNDLE_ID + ", app-clip-display=card",
        };
        (await import("../../util/app-clip-logic")).execute(getAppClipUrl(), details);
    }
};

// Modal Events
export function closeAppClipModal() {
    const modal = getACModal();

    if (modal) {
        const instance = Modal.init(modal);
        instance.close();
    }
}

export function showAppClipModal() {
    const modal = getACModal();

    if (modal) {
        analytics.page({
            name: "page_view",
            properties: {
                title: "App Clip Loading Modal",
                page_id: "app_clip_loading_modal",
            },
        });
        showSkipButton(false);
        const instance = Modal.init(modal, {
            onCloseEnd: (_, closeMethod) => {
                // Only trigger on overlay clicks
                if (closeMethod == "overlay") {
                    analytics.track({
                        name: "app_clip_modal_overlay_clicked",
                        properties: { page_id: "app_clip_loading_modal" },
                    });
                }
            },
        });
        instance.open();
    }

    const animationInstance = getLottieAnimationInstance(APP_CLIP_LOADING_ANIMATION_NAME);

    if (animationInstance) {
        animationInstance.loop = false;
        animationInstance.goToAndPlay(0);
    }
}

// Events

// Check incognito to hide AC experience
// This can't be done in the handleSaveContactBtnClick function
// because the detectIncognito function breaks the user-initiated
// event triggering the AC properly
let isIncognito: boolean | undefined = undefined;
export async function checkIncognito(): Promise<boolean> {
    if (isIncognito !== undefined) {
        return isIncognito;
    }
    const { isPrivate } = await detectIncognito();
    isIncognito = isPrivate;
    return isIncognito;
}
/**
 * CRITICAL FLOW
 * This function handles ALL user clicking the primary CTA "Save contact".
 */
export async function handleSaveContactBtnClick(isPreview: boolean, isAC: boolean, skipTrack?: boolean) {
    // Create a start time so we can workout the duration at the end of the flow // BEE-1098
    const startTime = Date.now();

    window.DD_LOGS?.onReady(() => {
        try {
            // TODO: looks like we're duplicating some metadata here with server-side logs. Evaluate if we need them?
            window.DD_LOGS?.setGlobalContextProperty("is_appclip", isAC);
            window.DD_LOGS?.setGlobalContextProperty("is_preview", isPreview);
            window.DD_LOGS?.setGlobalContextProperty("is_incognito", isIncognito);
        } catch (error) {
            console.error("Error setting global context properties", { error });
        }
    });

    if (!isAC || isIncognito) {
        console.debug("Saving contact without app clip"); // BEE-1007
        return await handleNonAcSaveContact(isPreview, skipTrack);
    }
    // AC LOGIC HERE
    analytics.track({
        name: "save_contact_button_clicked",
        properties: {
            page_id: "card",
        },
    });
    // Open modal behind app clip
    // When it is Safari delay the modal to prevent user seeing the it before the app clip on safari
    setTimeout(
        () => {
            console.debug("Show app clip modal"); // BEE-1007
            showAppClipModal();
        },
        (await isSafari()) ? 2000 : 0
    );

    // Wait for animation before loading app clip
    await new Promise((resolve) => setTimeout(resolve, APP_CLIP_LOADING_ANIMATION_LOOP_DELAY_MS));
    // Warm cache or load app clip
    console.debug("Redirecting to app clip"); // BEE-1007
    window.location.href = getAppClipUrl(startTime.toString());
}

export const handleSkipAppClickBtnClick = () => {
    analytics.track({
        name: "app_clip_modal_skip_clicked",
        properties: {
            page_id: "app_clip_loading_modal",
        },
    });

    closeAppClipModal();
    // If the user wants to _go back_ from the share 'Send Contact' modal, we
    // call this callback, which will re-open the app clip modal
    const goBackCallback = () => {
        showAppClipModal();
    };

    showSaveContactViaEmailInsteadModal(goBackCallback);
};

export const handleAppClipOpenBtnClick = () => {
    // Create a start time so we can workout the duration at the end of the flow // BEE-1098
    const startTime = Date.now();

    analytics.track({
        name: "app_clip_modal_okay_clicked",
        properties: {
            page_id: "app_clip_loading_modal",
        },
    });
    window.location.href = getAppClipUrl(startTime.toString());
    showSkipButton(true);
};

// These run on mount to set up listeners, and meta tags
// They have conditions inside them to only run when necessary
checkIncognito();
setupAC();
setupACOpenButton();
setupACLoadingAnimation();
