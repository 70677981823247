import { isMobile } from "./platform-detection";

export const SHARE_DETAILS_STORAGE_KEY = "blinq-share-details";

export function prefillShareDetails() {
    if (!isMobile()) {
        return;
    }

    const previousShareDetailsInfo = JSON.parse(localStorage.getItem(SHARE_DETAILS_STORAGE_KEY) || "{}");

    for (const [k, v] of Object.entries(previousShareDetailsInfo)) {
        const key = k as string;
        const value = v as string;

        if (value) {
            const inputField = document.getElementById(key);
            if (inputField instanceof HTMLInputElement || inputField instanceof HTMLTextAreaElement) {
                inputField.value = value;
            }

            const label = document.getElementById(key + "_label");
            if (label) {
                label.classList.add("active");
            }
        }
    }
}
