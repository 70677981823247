import { ExperimentIdentifier } from "common/utils/experiments";
import { getLottieAnimationInstance } from "../../modules/animations";
import {
    CONGRATULATION_GET_BLINQ_ANIMATION_NAME,
    GET_BLINQ_STAGE_1_ANIMATION_NAME,
    GET_BLINQ_STAGE_2_ANIMATION_NAME,
    OPAQUE_TOKEN_KEY,
} from "../constants";
import { createLoginToken } from "../events/ui";
import { showGetBlinqModal } from "../modals";
import { getCardData, hidePrivacyStatementInSendContactsBackModal } from "../utils";

/**
 * Start showing the get blinq modal and play the lottie animation
 */
function beginGetBlinqFlow(experiment: ExperimentIdentifier = "E000_V000") {
    const animationInstance = getLottieAnimationInstance(CONGRATULATION_GET_BLINQ_ANIMATION_NAME);
    const hasAnimation = experiment === "E000_V000" || "E003_V001";

    const stage1Animation = getLottieAnimationInstance(GET_BLINQ_STAGE_1_ANIMATION_NAME);
    const stage2Animation = getLottieAnimationInstance(GET_BLINQ_STAGE_2_ANIMATION_NAME);
    const stage1AnimationEl = document.getElementById("get-blinq-multistage-animation-stage-1");
    const stage2AnimationEl = document.getElementById("get-blinq-multistage-animation-stage-2");
    const multiStageAnimationElementsArePresent =
        stage1Animation && stage2Animation && stage1AnimationEl && stage2AnimationEl;

    if (multiStageAnimationElementsArePresent) {
        stage1Animation.goToAndStop(0);
        stage2Animation.goToAndStop(0);
        stage1AnimationEl.style.display = "block";
        stage2AnimationEl.style.display = "none";
    }

    // If running the default modal, reset Lottie animation
    if (hasAnimation && animationInstance) {
        animationInstance.goToAndStop(0);
    }

    showGetBlinqModal(() => {
        // If running the default modal, play Lottie animation
        if (hasAnimation && animationInstance) {
            animationInstance.play();
        }

        const multiStageAnimationElementsArePresent =
            stage1Animation && stage2Animation && stage1AnimationEl && stage2AnimationEl;
        if (multiStageAnimationElementsArePresent) {
            stage1Animation.play();
            stage1Animation.addEventListener("complete", () => {
                stage1AnimationEl.style.display = "none";
                stage2AnimationEl.style.display = "block";
                stage2Animation.loop = true;
                stage2Animation.play();
            });
        }

        // when the modal opens, create a one time token to be used later
        createLoginToken()
            .then((token) => token && sessionStorage.setItem(OPAQUE_TOKEN_KEY, token))
            .catch((error) => console.error("Error creating login token", error));
    });

    hidePrivacyStatementInSendContactsBackModal();
}

/**
 * This function will display the Get Blinq modal if the card data indicates that we should
 */
export function displayGetBlinqPromptIfNecessary() {
    const cardData = getCardData();
    if (cardData.displayGetBlinqPrompt === false) {
        return;
    }
    beginGetBlinqFlow();
}
