import textFieldTemplate from "./text-field.hbs";

enum TextFieldTypes {
    EMAIL = "email",
    PHONE_NUMBER = "phone_number",
    NAME = "name",
    JOB_TITLE = "job_title",
    COMPANY_NAME = "org_name",
}

interface TemplateInputs {
    id: string;
    dataValidationGroup: string | null;
    inputId: string;
    inputName: string;
    inputType: string;
    inputPlaceholder: string;
    inputAutocomplete: string;
    inputErrorText: string | null;
}

function buildTextFieldForShareDetailsBackForm(textFieldType: TextFieldTypes): string {
    let templateInputs: TemplateInputs;

    switch (textFieldType) {
        case TextFieldTypes.EMAIL:
            templateInputs = {
                id: "contact-info-email",
                dataValidationGroup: "contact-info",
                inputId: "email",
                inputName: "email",
                inputType: "email",
                inputPlaceholder: "Email",
                inputAutocomplete: "email",
                inputErrorText: "Please enter your email address",
            };
            break;
        case TextFieldTypes.PHONE_NUMBER:
            templateInputs = {
                id: "contact-info-phone-number",
                dataValidationGroup: "contact-info",
                inputId: "phone_number",
                inputName: "phone_number",
                inputType: "tel",
                inputPlaceholder: "Phone number",
                inputAutocomplete: "tel",
                inputErrorText: "Please enter your phone number",
            };
            break;
        case TextFieldTypes.NAME:
            templateInputs = {
                id: "contact-info-name",
                dataValidationGroup: null,
                inputId: "name",
                inputName: "name",
                inputType: "text",
                inputPlaceholder: "Name",
                inputAutocomplete: "name",
                inputErrorText: "Please enter your name",
            };
            break;
        case TextFieldTypes.JOB_TITLE:
            templateInputs = {
                id: "contact-info-job-title",
                dataValidationGroup: null,
                inputId: "job_title",
                inputName: "job_title",
                inputType: "text",
                inputPlaceholder: "Job title",
                inputAutocomplete: "organization-title",
                inputErrorText: null,
            };
            break;
        case TextFieldTypes.COMPANY_NAME:
            templateInputs = {
                id: "contact-info-org-name",
                dataValidationGroup: null,
                inputId: "org_name",
                inputName: "org_name",
                inputType: "text",
                inputPlaceholder: "Company name",
                inputAutocomplete: "organization",
                inputErrorText: null,
            };
            break;
    }

    return textFieldTemplate(templateInputs);
}

export { TextFieldTypes, buildTextFieldForShareDetailsBackForm };
