export function formatDate(date: Date) {
    const day = date.getUTCDate();
    const month = date.getUTCMonth() + 1; //months from 1-12
    const year = date.getUTCFullYear().toString();

    const formattedDay = day.toString().padStart(2, "0");
    const formattedMonth = month.toString().padStart(2, "0");

    const formattedFullDate = `${year}-${formattedMonth}-${formattedDay}`;

    return { day: formattedDay, month: formattedMonth, year, full: formattedFullDate };
}
