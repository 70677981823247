import { Modal } from "../controllers";
import {
    OnCloseModalOption,
    ShowModalCallbackOptions,
    mapModalCloseMethodToAnalyticsEventName,
} from "../controllers/modal";
import { analytics } from "./analytics";

export function showIsPreviewModal() {
    const modalEl = document.getElementById("is-preview-modal");

    if (modalEl) {
        const instance = Modal.init(modalEl);
        instance.open();
    }
}

/**
 * On iOS in Safari we provide an explainer modal showing how to save a contact before we save and
 * open the vcard (we can't save the vcard for the customer)
 * @param onCloseCallback We call this function when the modal closes
 * @param onOpenFinishCallback We call this function when the modal opens
 */
export function showIOSExplainerModal(
    onCloseCallback: Modal["options"]["onCloseEnd"],
    onOpenFinishCallback?: () => void
) {
    const modalEl = document.getElementById("save-contact-ios-safari-explainer-modal") as HTMLElement;
    if (modalEl) {
        const instance = Modal.init(modalEl, {
            onCloseEnd: onCloseCallback,
        });
        instance.open(onOpenFinishCallback);

        analytics.page({
            name: "page_view",
            properties: {
                title: "Save Contact Explainer",
                page_id: "ios_safari_explainer_modal",
            },
        });
    }
}

/**
 * On android we provide an explainer modal showing how to save a contact before we save and
 * open the vcard (we can't save the vcard for the customer)
 */
export function showAndroidExplainerModal(onFinish: Modal["options"]["onCloseEnd"]) {
    const modalEl = document.getElementById("save-contact-android-explainer-modal");

    if (modalEl) {
        const instance = Modal.init(modalEl, {
            onCloseEnd: onFinish,
        });
        instance.open();

        analytics.page({
            name: "page_view",
            properties: {
                title: "Save Contact Explainer",
                page_id: "android_explainer_modal",
            },
        });
    }
}

export function closeIOSExplainerModal(oncloseCallbacks?: OnCloseModalOption) {
    const modalEl = document.getElementById("save-contact-ios-safari-explainer-modal");

    if (modalEl) {
        const instance = Modal.getInstance(modalEl);
        if (instance) instance.close(oncloseCallbacks);
    }
}

export function closeAndroidExplainerModal(oncloseCallbacks?: OnCloseModalOption) {
    const modalEl = document.getElementById("save-contact-android-explainer-modal");

    if (modalEl) {
        const instance = Modal.getInstance(modalEl);
        if (instance) instance.close(oncloseCallbacks);
    }
}

export function closeSaveContactViaEmailInsteadModal(oncloseCallbacks?: OnCloseModalOption) {
    const modalEl = document.getElementById("save-contact-via-email-instead-container");

    if (modalEl) {
        const instance = Modal.getInstance(modalEl);
        if (instance) instance.close(oncloseCallbacks);
    }
}

type ShowShareCardDetailsModalOptions = {
    callbackOptions: ShowModalCallbackOptions;
    showCloseOrSkipButton?: boolean;
};

/**
 * We want to provide a way for the recipient of a Blinq card to share their details back with the
 * card owner.
 */
export function showShareContactDetailsModal({
    callbackOptions,
    showCloseOrSkipButton = true,
}: ShowShareCardDetailsModalOptions) {
    const modalEl = document.getElementById("share-contact-info-modal");

    if (modalEl) {
        const instance = Modal.init(modalEl, {
            ...callbackOptions,
            showCloseOrSkipButton,
        });
        instance.open();

        analytics.page({
            name: "page_view",
            properties: {
                title: "Share Contact Details",
                page_id: "share_contact_details_modal",
            },
        });
    }
}

export function closeShareContactDetailsModal(oncloseCallbacks?: OnCloseModalOption) {
    const modalEl = document.getElementById("share-contact-info-modal");

    if (modalEl) {
        const instance = Modal.getInstance(modalEl);
        if (instance) instance.close(oncloseCallbacks);
    }
}

export function hideDownloadContactButtonFromSaveContactModal(headingText: string) {
    const saveContactToPhoneBtn = document.getElementById("save-contact-to-phone-btn");
    const saveContactModalEl = document.getElementById("save-contact-modal") as HTMLElement;
    const byEmailForm = document.getElementById("send-via-email-form");
    const byEmailRadioBtn = document.getElementById("send-via-email-radio-btn") as
        | HTMLInputElement
        | undefined;
    const saveContactModalHeaderEl = document.getElementById(
        "save-contact-modal-header"
    ) as HTMLHeadingElement;
    saveContactToPhoneBtn?.classList.remove("visible");
    (document.querySelector("#add-to-contacts-radio-btn + label") as HTMLElement).style.display = "none";

    // set the default to sms
    saveContactModalEl?.setAttribute("data-tab", "email");
    saveContactModalEl?.setAttribute("data-default-tab", "email");

    byEmailForm?.classList.add("visible");
    if (byEmailRadioBtn) byEmailRadioBtn.checked = true;
    saveContactModalHeaderEl.innerHTML = headingText;
    document.querySelector(".slider")?.classList.add("half-width");
}

export function showSaveContactModal(callbackOptions: ShowModalCallbackOptions) {
    const modalEl = document.getElementById("save-contact-modal");
    if (modalEl) {
        const instance = Modal.init(modalEl, callbackOptions);
        instance.open();

        analytics.page({
            name: "page_view",
            properties: {
                title: "Save Contact",
                page_id: "send_details_to_self_modal",
            },
        });
    }
}

export function closeSaveContactModal(oncloseCallbacks?: OnCloseModalOption) {
    const modalEl = document.getElementById("save-contact-modal");

    if (modalEl) {
        const instance = Modal.getInstance(modalEl);
        if (instance) instance.close(oncloseCallbacks);
    }
}

export function showSelectCountryModal(onFinish?: Modal["options"]["onCloseEnd"]) {
    const modalEl = document.getElementById("select-country-modal");

    if (modalEl) {
        const instance = Modal.init(modalEl, { onCloseEnd: onFinish });
        instance.open();
        analytics.page({
            name: "page_view",
            properties: {
                title: "Select a Country",
                page_id: "select_country_modal",
            },
        });
    }
}

export function closeSelectCountryModal(oncloseCallbacks?: OnCloseModalOption) {
    const modalEl = document.getElementById("select-country-modal");

    if (modalEl) {
        const instance = Modal.getInstance(modalEl);
        if (instance) instance.close(oncloseCallbacks);
    }
}

/* 
    To render a personalized card preview, we require the first_name and last_name fields
    as well as at least two additional fields.

    This is so the card looks sufficiently populated.
*/
export const setup_E003_V005 = () => {
    const { first_name, last_name, email, job_title, org_name, phone_number } =
        Blinq.getShareDetailsFormData();

    const additionalFields = [email, job_title, org_name, phone_number].filter((item) => item);
    const canRenderPersonalizedCard = Boolean(first_name && last_name && additionalFields.length >= 2);

    const fallbackCardImage = document.getElementById("example-card-image") as HTMLDivElement | null;
    if (!fallbackCardImage) return;

    const deviceContainer = document.querySelector(".device-frame-container") as HTMLDivElement | null;
    if (!deviceContainer) return;

    const headingText = document.querySelector(".get-blinq-heading-text")?.querySelector("p");
    if (!headingText) return;

    // Reset 'Get Blinq' modal UI to expected state
    fallbackCardImage.style.display = "none";
    deviceContainer.style.display = "flex";
    headingText.style.maxWidth = "230px";
    headingText.innerText = "Share your details in a Blinq";

    /* 
        If we don't have sufficient data to display a personalized card then
        we fallback to E003_V004, which is a simpler 'Get Blinq' modal:
    */
    if (!canRenderPersonalizedCard) {
        fallbackCardImage.style.display = "flex";
        deviceContainer.style.display = "none";
        headingText.style.maxWidth = "330px";
        headingText.innerText = "Get your digital business card in seconds";
        return;
    }

    const fullNameField = document.getElementById("mockup-full-name");
    const jobTitleField = document.getElementById("mockup-job-title");
    const orgNameField = document.getElementById("mockup-org-name");
    const emailFieldContainer = document.getElementById("mockup-email-field-container");
    const emailField = document.getElementById("mockup-email");
    const phoneNumberFieldContainer = document.getElementById("mockup-phone-field-container");
    const phoneNumberField = document.getElementById("mockup-phone-number");

    if (fullNameField) {
        fullNameField.classList.remove("hidden");
        fullNameField.innerText = `${first_name} ${last_name}`;
    }

    if (jobTitleField && job_title) {
        jobTitleField.classList.remove("hidden");
        jobTitleField.innerText = job_title;
    }

    if (orgNameField && org_name) {
        orgNameField.classList.remove("hidden");
        orgNameField.innerText = org_name;
    }

    if (emailFieldContainer && emailField && email) {
        emailFieldContainer.classList.remove("hidden");
        emailField.innerText = email;
    }

    if (emailFieldContainer && emailField && email) {
        emailFieldContainer.classList.remove("hidden");
        emailField.innerText = email;
    }

    if (phoneNumberFieldContainer && phoneNumberField && phone_number) {
        phoneNumberFieldContainer.classList.remove("hidden");
        phoneNumberField.innerText = phone_number;
    }
};

/**
 * On iOS on Safari and on android devices the user doesn't have to navigate away from the Blinq card page
 * in order to save the contact. So we can show the user another modal after they have saved the contact and
 * shared their details that prompts them to download Blinq and create there own business card.
 */
export function showGetBlinqModal(onOpenFinishCallback?: () => void) {
    const modalEl = document.getElementById("get-blinq-modal") as HTMLDivElement;
    const congratulationHeader = document.querySelector(
        ".get-blinq-congratulation-header"
    ) as HTMLDivElement | null;
    const modalContentEl = modalEl.querySelector(".modal-content") as HTMLDivElement;

    if (congratulationHeader) {
        const firstName = Blinq.getShareDetailsFormData().first_name;

        if (firstName) {
            congratulationHeader.classList.remove("hidden");
            congratulationHeader.innerHTML =
                firstName.length < 15
                    ? `<p>🎉 Great job,  <em>${firstName}!</em></p>`
                    : "<p>🎉 Great job!</p>";
            modalContentEl.style.paddingTop = "0";
        } else {
            congratulationHeader.classList.add("hidden");
            modalContentEl.style.paddingTop = "1rem";
        }
    }

    /* 
        If we detect the device-frame-container class in the document then we're running
        experiment ID E003_V005 and need to populate the html card mockup with the form
        data pulled from the Share Details modal.
    */
    const deviceFrameContainer = document.querySelector(".device-frame-container") as HTMLDivElement | null;
    if (deviceFrameContainer) {
        setup_E003_V005();
    }

    if (modalEl) {
        const hasCardPreview = !!document.getElementById("card-preview-component");
        const pageId = hasCardPreview ? "get_blinq_modal_personalized" : "get_blinq_modal";

        const instance = Modal.init(modalEl, {
            onCloseEnd: (_, closeMethod) => {
                const closeModalEventName = mapModalCloseMethodToAnalyticsEventName(closeMethod);

                if (closeModalEventName) {
                    analytics.track({
                        name: closeModalEventName,
                        properties: { page_id: pageId },
                    });
                }
            },
        });
        instance.open(onOpenFinishCallback);

        analytics.page({
            name: "page_view",
            properties: {
                title: "Get Blinq",
                page_id: pageId,
            },
        });
    }
}
