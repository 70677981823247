import anime from "animejs";
import ToastTemplate from "../../templates/partials/toast.hbs";
import { fromHTMLString } from "../utils";

interface PaperDesignSystemToastProps {
    primaryText: string;
    emphasisedText?: string;
    duration?: number;
    autoDismiss?: boolean;
    onDisappear?: () => void | Promise<void>;
    toastType?: "success" | "error";
}

function PaperDesignSystemToast({
    primaryText,
    emphasisedText,
    onDisappear,
    autoDismiss = true,
    duration = 3000,
    toastType = "success",
}: PaperDesignSystemToastProps) {
    let toastContainerNode: HTMLElement;
    let timeoutId: number | null = null;

    function render() {
        const toastContainer = ToastTemplate();
        toastContainerNode = fromHTMLString(toastContainer) as HTMLElement;
        const primaryTextComponent = document.createElement("span");
        primaryTextComponent.appendChild(document.createTextNode(primaryText + " "));
        const messageContainerNode = toastContainerNode.getElementsByClassName("messages-container")[0];
        messageContainerNode.appendChild(primaryTextComponent);
        if (emphasisedText) {
            const emphasisedTextNode = document
                .createElement("b")
                .appendChild(document.createTextNode(emphasisedText));
            messageContainerNode.appendChild(emphasisedTextNode);
        }

        const toastNode = toastContainerNode.getElementsByClassName("toast")[0];

        document.body.appendChild(toastContainerNode);

        const iconNode = toastContainerNode.getElementsByClassName("toast-icon")[0];

        switch (toastType) {
            case "success":
                iconNode.classList.add("fa-check-circle");
                break;
            case "error":
                iconNode.classList.add("fa-times-circle");
                toastNode.classList.add("error");
                break;
            default:
                iconNode.classList.add("fa-check-circle"); // Default icon
                break;
        }

        anime({
            targets: toastNode,
            translateY: ["-100%", 0],
            duration: 450,
            easing: "easeOutQuad",
        });
        if (!autoDismiss) {
            return;
        }

        timeoutId = window.setTimeout(() => {
            timeoutId = null;
            anime({
                targets: toastNode,
                translateY: [0, "-100%"],
                opacity: [1, 0],
                duration: 300,
                easing: "easeOutQuad",
                complete: () => {
                    unmount();
                },
            });
        }, duration);
    }

    function unmount() {
        if (timeoutId) {
            clearTimeout(timeoutId);
        }
        toastContainerNode.remove();
        onDisappear?.();
    }

    return { render, unmount };
}

export { PaperDesignSystemToast };
