export class Queue<T> {
    #items: T[];
    #isProcessing = false;
    #handleFn: ((element: T) => Promise<void>) | undefined;

    constructor() {
        this.#items = [];
        this.#handleFn = undefined;
    }

    /**
     * Adds an element to the queue. Items will be automatically processed once they are added to the queue.
     * If the queue handle function is async then each item in the queue will only be processed once the item before
     * it has completed it's async handle function.
     */
    enqueue(element: T) {
        this.#items.push(element);
        this.startProcessing();
    }

    /**
     * Add a handle function to handle each element in the queue.
     * @param handleFn A function that will be called for each element in the queue
     * @returns The queue object
     */
    setHandler(handleFn: (element: T) => Promise<void>): Queue<T> {
        this.#handleFn = handleFn;
        return this;
    }

    /**
     * Removes and returns the front element of the queue
     */
    dequeue(): T | null {
        if (this.isEmpty()) {
            return null;
        }
        return this.#items.shift() ?? null;
    }

    /**
     * Process each element in the queue, if the handle function is async then
     * each item in the queue will only be processed once the item before
     * it has completed it's async handle function.
     */
    async startProcessing() {
        if (this.#isProcessing || !this.#handleFn) {
            return;
        }

        this.#isProcessing = true;

        while (!this.isEmpty()) {
            const element = this.dequeue();
            if (element) {
                await this.#handleFn(element);
            }
        }
        this.#isProcessing = false;
    }

    isEmpty(): boolean {
        return this.#items.length === 0;
    }
}
