import { mapModalCloseMethodToAnalyticsEventName } from "../../controllers/modal";
import { FUNCTIONS_HOST } from "../../env";
import { progressBarAnimation } from "../../modules/animations";
import { blinqApiGraphQLClient, postData } from "../../modules/api";
import { isBlacklistedBrowserType, isIOS } from "../../modules/platform-detection";
import { analytics } from "../analytics";
import { storeInstance } from "../analytics/store";
import { DATA_FLOWS, EXPERIMENT_2_FLAG } from "../constants";
import {
    closeAndroidExplainerModal,
    closeIOSExplainerModal,
    showAndroidExplainerModal,
    showIsPreviewModal,
} from "../modals";
import {
    autoFillShareDetailsBackForm,
    checkModalFlowType,
    downloadVCard,
    getCardData,
    getDateOfExchange,
    getLocationOfExchange,
    getOS,
    getVCardLocation,
    isMobilePlatform,
    resetSaveContactForm,
    shouldWeShowAndroidExplainerModal,
    shouldWeShowIOSExplainerModal,
} from "../utils";
import {
    showIOSExplainerModalWithDefaultBehavior,
    showSaveContactModalWithDefaultBehavior,
} from "./explainer";
import { displayGetBlinqPromptIfNecessary } from "./get-blinq";
import { startSendContactBackFlow } from "./send-contact";

/** Saves the contact via API to claim via session continuity */
function addContactAsAnonymous() {
    // run in the background
    blinqApiGraphQLClient
        .addContact({
            cardId: getCardData().cardId,
            location: getLocationOfExchange(),
        })
        .catch((error) => console.error("Error adding contact", { error }));
}

/**
 * Send the card's vcf via email to the recipient
 * @param email - The recipient's email to send the card to
 */
export function receiveCardViaEmail(email: string) {
    // run in the background
    blinqApiGraphQLClient
        .receiveCardViaEmail({
            email: email,
            card_id: getCardData().cardId,
            location: getLocationOfExchange(),
            date_of_exchange: getDateOfExchange().full,
            operating_system: isIOS() ? "ios" : "android",
            referral_data: storeInstance.getReferralData(),
        })
        .catch((error) => console.error("Failed to receive card via email", { error }));
}

/**
 * Send the card's vcf via email to the recipient
 * @param emailToSendTo - The recipient's email to send the card to
 */
export async function receiveCardViaEmailCloudFunction(emailToSendTo: string) {
    const cardData = getCardData();
    const locationOfExchange = getLocationOfExchange();
    const dateExchangedCard = getDateOfExchange();
    const operatingSystem = isIOS() ? "ios" : "android";
    const data = {
        referrerCardId: cardData.cardId,
        toEmail: emailToSendTo,
        location: locationOfExchange,
        date: dateExchangedCard.full,
        os: operatingSystem,
        referralData: storeInstance.getReferralData(),
    };
    const url = `${FUNCTIONS_HOST}/sendContactCardToSelfViaEmail`;
    await postData(url, data);
}
/**
 * This will show the explainer modal for android, the default behaviour is when the modal is closed,
 * we will open the modal that allows users to share contact details back.
 */
export function showAndroidExplainerModalWithDefaultBehavior({
    googleSaveLoading,
}: {
    googleSaveLoading?: boolean;
} = {}) {
    const cardData = getCardData();

    const header = document.getElementById("save-contact-android-explainer-modal-header");
    const emailBtn = document.getElementById("save-contact-android-explainer-modal-email-btn");
    const divider = document.querySelector(
        "#save-contact-android-explainer-modal .divider"
    ) as HTMLElement | null;

    // Hiding the email button and divider when the contact is being auto-saved to Google Contacts
    if (googleSaveLoading) {
        if (header) {
            header.textContent = "The contact is being auto-saved to your phone using Google Contacts.";
            header.style.paddingTop = "1rem";
        }
        if (emailBtn) emailBtn.style.display = "none";
        if (divider) divider.style.display = "none";
    }

    showAndroidExplainerModal((_, closeMethod) => {
        const closeModalEventName = mapModalCloseMethodToAnalyticsEventName(closeMethod);

        if (closeModalEventName) {
            analytics.track({
                name: closeModalEventName,
                properties: { page_id: "android_explainer_modal" },
            });
        }

        // Revert to original after the google save is done
        if (googleSaveLoading) {
            if (header) {
                header.textContent = "";
                header.style.paddingTop = "0";
            }
            if (emailBtn) emailBtn.style.display = "flex";
            if (divider) divider.style.display = "block";
        }

        startSendContactBackFlow(
            cardData.displayGetBlinqPrompt ?? true,
            showAndroidExplainerModalWithDefaultBehavior, // show the explainer modal again if the users click go back
            true
        );
    });
}

/**
 * This function handles user clicking the primary CTA "Save contact".
 */
export async function handleNonAcSaveContact(isPreview: boolean, skipTrack?: boolean) {
    if (isPreview) {
        showIsPreviewModal();
        return;
    }
    addContactAsAnonymous();

    if (!skipTrack) {
        analytics.track({
            name: "save_contact_button_clicked",
            properties: {
                page_id: "card",
            },
        });
    }

    if (EXPERIMENT_2_FLAG && !isBlacklistedBrowserType() && isMobilePlatform()) {
        handleExperiment2SaveContactFlow();
        return;
    }
    const weShouldShowIOSExplainer = await shouldWeShowIOSExplainerModal();
    if (weShouldShowIOSExplainer) {
        showIOSExplainerModalWithDefaultBehavior();
        return;
    }
    const weShouldShowAndroidExplainer = shouldWeShowAndroidExplainerModal();
    if (weShouldShowAndroidExplainer) {
        showAndroidExplainerModalWithDefaultBehavior();
        return;
    }
    showSaveContactModalWithDefaultBehavior();
}

/**
 * This function mainly resets the save contact form and start the sharing details back
 * to card's owner flow.
 */
export function cleanUpWhenSaveContactModalCloses() {
    const saveContactModalEl = document.getElementById("save-contact-modal") as HTMLElement;
    saveContactModalEl.setAttribute(
        "data-tab",
        saveContactModalEl.getAttribute("data-default-tab") || "email"
    );

    const cardData = getCardData();
    startSendContactBackFlow(
        cardData.displayGetBlinqPrompt ?? true,
        showSaveContactModalWithDefaultBehavior,
        true
    );
    autoFillShareDetailsBackForm();
    resetSaveContactForm();
}

/**
 * This function handles the Experiment 2 save contact flow
 *
 * On mobile, we skip the Explainer modal, start the contact
 * download immediately and proceed to the Share Contact
 * Details modal
 *
 */
async function handleExperiment2SaveContactFlow() {
    await downloadVCardWithProgressAnimation();

    analytics.track({
        name: "contact_saved",
        properties: { save_card_method: "download_vcf" },
    });

    startObservingHowLongUserSavesContact();

    const cardData = getCardData();

    const displayGetBlinqPrompt = cardData.displayGetBlinqPrompt ?? true;
    const onGoBackCallBackForSendContactModal = () => {
        return;
    }; // no-op
    // Since there's no preceding modal to display the privacy statement in this revised flow,
    // we always want to show the privacy statement on the send contact back modal instead.
    const showPrivacyStatement = true;

    startSendContactBackFlow(
        displayGetBlinqPrompt,
        onGoBackCallBackForSendContactModal,
        showPrivacyStatement
    );
}

async function downloadVCardWithProgressAnimation() {
    storeInstance.saveContactMethod = "save";
    downloadVCard();
    const { animation } = progressBarAnimation(".save-btn");
    const doneAnimation = animation({ autoplay: false });
    try {
        // Play the animation
        doneAnimation.play();
        // Wait for the thing to download
        await fetch(getVCardLocation());
    } catch (error) {
        // if there's an error then wait for the animation to finish
        await doneAnimation.finished;
    } finally {
        // reset the animation
        doneAnimation.seek(0);
        doneAnimation.pause();
    }
}

/**
 * This function observes how long the user takes to save the contact on the native UI before coming back to the app.
 * We measure this by observing the time between the user tapping the 'Save Contact' button and the user making the first
 * touch in the app.
 * */
function startObservingHowLongUserSavesContact() {
    const currentOS = getOS();
    if (currentOS !== "iphone" && currentOS !== "android") {
        return;
    }

    const startTimestamp = Date.now();
    document.addEventListener("touchstart", () => observeHowLongUserSavesContact(startTimestamp, currentOS), {
        once: true,
    });
}

export const observeHowLongUserSavesContact = (startTimestamp: number, os: "android" | "iphone") => {
    const timeTakenToSaveInSecs = (Date.now() - startTimestamp) / 1000;
    const roundedToHalf = Math.round(timeTakenToSaveInSecs * 2) / 2; // rounded to 0.5, 1 or 4.5

    // todo: find a way to record this?
    analytics.track({
        name: "returned_to_card",
        properties: { seconds: roundedToHalf, os },
    });
};

/**
 * This function will download the card and let the user save the contact details to
 * their phone from the explainer modal.
 *
 */
export async function handleDownloadContactFromExplainerModal(pageId: string) {
    const weShowedExplainerForIOS = await shouldWeShowIOSExplainerModal();

    analytics.track({
        name: "contact_saved",
        properties: { save_card_method: "download_vcf", page_id: pageId },
    });
    const startTimestamp = Date.now();
    document.addEventListener(
        "touchstart",
        () => observeHowLongUserSavesContact(startTimestamp, weShowedExplainerForIOS ? "iphone" : "android"),
        {
            once: true,
        }
    );

    if (weShowedExplainerForIOS) {
        const isSwapFlow = checkModalFlowType(
            "save-contact-ios-safari-explainer-modal",
            DATA_FLOWS.SWAP_DETAILS
        );

        closeIOSExplainerModal({
            beforeCloseCallback: downloadVCardWithProgressAnimation,
            overrideOnFinishCallback: isSwapFlow ? () => displayGetBlinqPromptIfNecessary() : undefined,
        });
        return;
    }
    const isSwapFlow = checkModalFlowType("save-contact-android-explainer-modal", DATA_FLOWS.SWAP_DETAILS);

    closeAndroidExplainerModal({
        beforeCloseCallback: downloadVCardWithProgressAnimation,
        overrideOnFinishCallback: isSwapFlow ? () => displayGetBlinqPromptIfNecessary() : undefined,
    });
}
