import {
    CloseMethod,
    closeMethodIsASkip,
    mapModalCloseMethodToAnalyticsEventName,
} from "../../controllers/modal";
import { getLottieAnimationInstance } from "../../modules/animations";
import { analytics } from "../analytics";
import {
    DATA_FLOWS,
    EXPLAINER_ANIMATION_LOOP_DELAY_MS,
    IOS_SAVE_CONTACT_EXPLAINER_ANIMATION_NAME,
} from "../constants";
import {
    closeAndroidExplainerModal,
    closeIOSExplainerModal,
    closeSaveContactViaEmailInsteadModal,
    showAndroidExplainerModal,
    showIOSExplainerModal,
    showIsPreviewModal,
    showSaveContactModal,
} from "../modals";
import {
    checkModalFlowType,
    getCardData,
    resetSaveContactForm,
    shouldWeShowAndroidExplainerModal,
    shouldWeShowIOSExplainerModal,
} from "../utils";
import { displayGetBlinqPromptIfNecessary } from "./get-blinq";
import {
    cleanUpWhenSaveContactModalCloses,
    showAndroidExplainerModalWithDefaultBehavior,
} from "./save-contact";
import { startSendContactBackFlow } from "./send-contact";

/**
 * Make the IOS animation play when the explainer modal opens
 * @param updateTimeoutID the callback function used to update the timeoutID
 * for clearing when the modal closes
 */
export function playIOSExplainerAnimationUponOpening(updateTimeoutID: (timeoutID: NodeJS.Timeout) => void) {
    const animationInstance = getLottieAnimationInstance(IOS_SAVE_CONTACT_EXPLAINER_ANIMATION_NAME);
    // If this thing can't be found something has gone seriously wrong
    if (!animationInstance) return;

    animationInstance.goToAndPlay(0);

    animationInstance.addEventListener("complete", () => {
        const explainerAnimationTimeoutId = setTimeout(function () {
            animationInstance.goToAndPlay(0);
        }, EXPLAINER_ANIMATION_LOOP_DELAY_MS);
        updateTimeoutID(explainerAnimationTimeoutId);
    });
}

/**
 * This will show the explainer modal for iOS, the default behaviour is when the modal is closed,
 * we will open the modal that allows users to share contact details back.
 */
export function showIOSExplainerModalWithDefaultBehavior() {
    const cardData = getCardData();
    let explainerAnimationTimeoutId: NodeJS.Timeout;

    showIOSExplainerModal(
        (_, closeMethod) => {
            if (explainerAnimationTimeoutId) clearInterval(explainerAnimationTimeoutId);

            const closeModalEventName = mapModalCloseMethodToAnalyticsEventName(closeMethod);

            if (closeModalEventName) {
                analytics.track({
                    name: closeModalEventName,
                    properties: { page_id: "ios_safari_explainer_modal" },
                });
            }

            startSendContactBackFlow(
                cardData.displayGetBlinqPrompt ?? true,
                showIOSExplainerModalWithDefaultBehavior, // show the explainer modal again if the users click go back
                true
            );
        },
        () => {
            playIOSExplainerAnimationUponOpening((timeoutID) => {
                explainerAnimationTimeoutId = timeoutID;
            });
        }
    );
}

/**
 * This function will show the save contact modal, the default behaviour is if the modal is closed
 * by the user, we will clean up the form and start the sharing details back to the card's owner
 * flow. If the user clicks go back, depending on the device they are on, the explainer modal
 * will open.
 */
export async function showSaveContactModalWithDefaultBehavior() {
    const didWeHaveToShowIOSExplainer = await shouldWeShowIOSExplainerModal();
    const didWeHaveToShowAndroidExplainer = shouldWeShowAndroidExplainerModal();

    let onGoBackCallbackFunction: () => void | undefined;
    if (didWeHaveToShowIOSExplainer) {
        onGoBackCallbackFunction = showIOSExplainerModalWithDefaultBehavior;
    } else if (didWeHaveToShowAndroidExplainer) {
        onGoBackCallbackFunction = showAndroidExplainerModalWithDefaultBehavior;
    }

    showSaveContactModal({
        onGoBack: () => {
            analytics.track({
                name: "sharecard_step_back_btn_clicked",
                properties: { page_id: "send_details_to_self_modal" },
            });
            onGoBackCallbackFunction?.();
        },
        onCloseEnd: (_, closeMethod) => {
            const closeModalEventName = mapModalCloseMethodToAnalyticsEventName(closeMethod);

            if (closeModalEventName) {
                analytics.track({
                    name: closeModalEventName,
                    properties: { page_id: "send_details_to_self_modal" },
                });
            }

            cleanUpWhenSaveContactModalCloses();
        },
    });
}

type ShowIOSExplainerModalWithCustomCallbackProps = {
    onClose?: (closeMethod: CloseMethod | undefined) => void;
};

/**
 * This will show the explainer modal for iOS and allow you to provide a custom callback when the modal is closed
 */
export function showIOSExplainerModalWithCustomCallbacks({
    onClose,
}: ShowIOSExplainerModalWithCustomCallbackProps) {
    let explainerAnimationTimeoutId: NodeJS.Timeout;

    showIOSExplainerModal(
        (_, closeMethod) => {
            if (explainerAnimationTimeoutId) clearInterval(explainerAnimationTimeoutId);

            const closeModalEventName = mapModalCloseMethodToAnalyticsEventName(closeMethod);

            if (closeModalEventName) {
                analytics.track({
                    name: closeModalEventName,
                    properties: { page_id: "ios_safari_explainer_modal" },
                });
            }
            onClose?.(closeMethod);
        },
        () => {
            playIOSExplainerAnimationUponOpening((timeoutID) => {
                explainerAnimationTimeoutId = timeoutID;
            });
        }
    );
}

export function showIOSExplainerWithSwapDetailsFlow() {
    document
        .getElementById("save-contact-ios-safari-explainer-modal")
        ?.setAttribute("data-flow", DATA_FLOWS.SWAP_DETAILS);

    showIOSExplainerModalWithCustomCallbacks({
        onClose: (closeMethod) => {
            if (closeMethodIsASkip(closeMethod)) {
                displayGetBlinqPromptIfNecessary();
                // If a skip, clean up the data attribute from the modal element
                document
                    .getElementById("save-contact-ios-safari-explainer-modal")
                    ?.removeAttribute("data-flow");
            }
        },
    });
}

export function showAndroidExplainerWithSwapDetailsFlow() {
    document
        .getElementById("save-contact-android-explainer-modal")
        ?.setAttribute("data-flow", DATA_FLOWS.SWAP_DETAILS);

    showAndroidExplainerModalWithCustomCallback({
        onClose: (closeMethod) => {
            if (closeMethodIsASkip(closeMethod)) {
                displayGetBlinqPromptIfNecessary();
                // If a skip, clean up the data attribute from the modal element
                document.getElementById("save-contact-android-explainer-modal")?.removeAttribute("data-flow");
            }
        },
    });
}

type ShowAndroidExplainerModalWithCustomCallbackOptions = {
    onClose?: (closeMethod: CloseMethod | undefined) => void;
};

export function showAndroidExplainerModalWithCustomCallback({
    onClose,
}: ShowAndroidExplainerModalWithCustomCallbackOptions) {
    showAndroidExplainerModal((_, closeMethod) => {
        const closeModalEventName = mapModalCloseMethodToAnalyticsEventName(closeMethod);

        if (closeModalEventName) {
            analytics.track({
                name: closeModalEventName,
                properties: { page_id: "android_explainer_modal" },
            });
        }

        onClose?.(closeMethod);
    });
}

/**
 * This is called when the user is shown the explainer modal for saving the contact to their phone
 * but they instead opt to send the contact to themselves via SMS or email
 */
export async function handleSaveViaSMSOrEmailClick(pageId: string, isPreview: boolean) {
    if (isPreview) {
        showIsPreviewModal();
    } else {
        analytics.track({
            name: "receive_via_email_button_clicked",
            properties: { page_id: pageId },
        });

        const weDidShowExplainerForIOS = await shouldWeShowIOSExplainerModal();
        if (weDidShowExplainerForIOS) {
            const isSwapFlow = checkModalFlowType(
                "save-contact-ios-safari-explainer-modal",
                DATA_FLOWS.SWAP_DETAILS
            );

            closeIOSExplainerModal({
                overrideOnFinishCallback: () => {
                    if (isSwapFlow) {
                        showSaveContactModal({
                            onCloseEnd: (_, closeMethod) => {
                                const closeModalEventName =
                                    mapModalCloseMethodToAnalyticsEventName(closeMethod);

                                if (closeModalEventName) {
                                    analytics.track({
                                        name: closeModalEventName,
                                        properties: { page_id: "send_details_to_self_modal" },
                                    });
                                }

                                displayGetBlinqPromptIfNecessary();
                                resetSaveContactForm();
                            },
                            onGoBack: () => {
                                analytics.track({
                                    name: "sharecard_step_back_btn_clicked",
                                    properties: { page_id: "send_details_to_self_modal" },
                                });
                                showIOSExplainerWithSwapDetailsFlow();
                            },
                        });

                        return;
                    }
                    showSaveContactModalWithDefaultBehavior();
                },
            });
        } else {
            const isSwapFlow = checkModalFlowType(
                "save-contact-android-explainer-modal",
                DATA_FLOWS.SWAP_DETAILS
            );

            closeAndroidExplainerModal({
                overrideOnFinishCallback: () => {
                    if (isSwapFlow) {
                        showSaveContactModal({
                            onCloseEnd: (_, closeMethod) => {
                                const closeModalEventName =
                                    mapModalCloseMethodToAnalyticsEventName(closeMethod);

                                if (closeModalEventName) {
                                    analytics.track({
                                        name: closeModalEventName,
                                        properties: { page_id: "send_details_to_self_modal" },
                                    });
                                }

                                displayGetBlinqPromptIfNecessary();
                                resetSaveContactForm();
                            },
                            onGoBack: () => {
                                analytics.track({
                                    name: "sharecard_step_back_btn_clicked",
                                    properties: { page_id: "send_details_to_self_modal" },
                                });
                                showAndroidExplainerWithSwapDetailsFlow();
                            },
                        });

                        return;
                    }
                    showSaveContactModalWithDefaultBehavior();
                },
            });

            // Related to Google Save Contact
            // After we recevied error from auth callback, we show EmailInsteadModal.
            // Here we close the modal and trigger the (Email) Save Contact Modal
            closeSaveContactViaEmailInsteadModal({
                overrideOnFinishCallback() {
                    showSaveContactModalWithDefaultBehavior();
                },
            });
        }
    }
}
