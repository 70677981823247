/*
 * This file is for the client environment
 */
export const isProduction = process.env.HOSTNAME_ME !== "nonprod.blnq.xyz" && !process.env.MINIFLARE;
export const isLocal = process.env.MINIFLARE;

export const ENVIRONMENT = isProduction ? "production" : "non-production";
export const VERSION = process.env.VERSION;

export const DASHBOARD_URL = process.env.MINIFLARE
    ? "http://localhost:3000"
    : `https://dash.${process.env.HOSTNAME_ME}`;

export const FUNCTIONS_HOST = process.env.MINIFLARE
    ? "http://127.0.0.1:8083/blnq-nonprod/us-central1"
    : process.env.FUNCTIONS_HOST;

export const HOSTNAME_ME = process.env.MINIFLARE ? null : process.env.HOSTNAME_ME;
export const DEEPLINK_BASE_URL = process.env.DEEPLINK_BASE_URL
    ? process.env.DEEPLINK_BASE_URL
    : "https://blnqnonprod.page.link";
export const ANDROID_APP_ID = isProduction ? "com.rabbl.blinq" : "com.rabbl.blinq.debug";
export const APPLE_APP_ID = isProduction ? "Blink" : "BlinkDev";
export const BASE_URL = process.env.MINIFLARE ? "http://localhost:8787" : `https://${HOSTNAME_ME}`;
export const GRAPHQL_ENDPOINT = process.env.MINIFLARE
    ? "http://localhost:8000/graphql"
    : `https://api.${HOSTNAME_ME}/graphql`;

const PROD_API_BASE_URL = "https://api.blinq.me";
const NONPROD_API_BASE_URL = "https://api.nonprod.blnq.xyz";

export const APP_CLIP_BUNDLE_ID = `com.rabbl.${APPLE_APP_ID}.Clip`;
export const APP_CLIP_URL = `https://appclip.apple.com/id?p=com.rabbl.${APPLE_APP_ID}.Clip`;

function getApiBaseUrl(): string {
    switch (true) {
        case HOSTNAME_ME?.includes("nonprod.blnq.xyz"):
            return NONPROD_API_BASE_URL;
        case HOSTNAME_ME?.includes("blinq.me"):
        default:
            return PROD_API_BASE_URL;
    }
}
export const API_BASE_URL = process.env.MINIFLARE ? "http://localhost:8000" : getApiBaseUrl();

export const EMAIL_DOMAIN_WORKER_URL = `${API_BASE_URL}/ed`;

export const AMPLITUDE_DEPLOYMENT_KEY =
    process.env.AMPLITUDE_DEPLOYMENT_KEY ?? "client-fGZifojWC6FeItsh3I9oKsEBzxdYQn79";

export const SEGMENT_WRITE_KEY = process.env.SEGMENT_WRITE_KEY ?? "iBPXvrM9VjG0Uowtb6IsHlPFU8xcYGLP";
