/**
 * Detect if the user is currently on an iOS device (e.g. iPad, iPhone, iPod)
 */
export function isIOS() {
    const ua = window.navigator.userAgent;
    return !!ua.match(/iPad/i) || !!ua.match(/iPhone/i) || !!ua.match(/iPod/i);
}

export function isMac() {
    return !!navigator.platform && /MacIntel/.test(navigator.platform);
}

/**
 * Detect if the user is currently in a safari browser using the navigator userAgent
 */
export async function isSafari() {
    const ua = window.navigator.userAgent;
    const iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
    const webkit = !!ua.match(/WebKit/i);
    const isBrave = await isBraveBrowser();

    const iOSSafari =
        iOS &&
        webkit &&
        !ua.match(/CriOS/i) && // Chrome for iOS
        !ua.match(/OPiOS/i) && // Opera for iOS
        !ua.match(/OPT\//i) && // Opera for iOS
        !isBrave && // https://github.com/brave/brave-browser/issues/10165#issuecomment-641128278
        !ua.match(/DuckDuckGo/i) &&
        !ua.match(/FxiOS/i); // Firefox for iOS

    console.debug("Is Safari - " + iOSSafari); // BEE-1007
    return iOSSafari;
}

export function isChromeIOS() {
    const ua = window.navigator.userAgent;
    return ua.match(/CriOS/i);
}

export async function isBraveBrowser() {
    return !!navigator.brave && (await navigator.brave.isBrave());
}

export function isIMessagePreview() {
    const ua = window.navigator.userAgent || window.navigator.vendor;
    const pattern = /facebookexternalhit|Facebot|Twitterbot|Applebot/i;
    return pattern.test(ua);
}

/**
 * Creating a blacklist for popular inApp browsers etc... This is
 * used to ensure we can avoid user frustration by providing them with options to download a contact
 * if they are in a browser that we know won't automatically download the vCard...
 * Surprisingly twitter in-app isn't an issue!
 * DuckDuckGo only has a problem on iOS devices.
 * UC Browser has received reports in the play store around downloads not finishig
 */
export function isBlacklistedBrowserType() {
    // fban = Facebook in-app browser on iOS
    // fbav = Facebook in-app browser on Android
    const browserBlackList = [
        "instagram",
        "linkedinapp",
        "fban",
        "fbav",
        "ucbrowser",
        "duckduckgo",
        "OPiOS",
        "OPT",
        "EdgiOS",
    ];
    const userAgent = window.navigator.userAgent || window.navigator.vendor;
    const isBlackListedBrowser = new RegExp(browserBlackList.join("|"), "gi").test(userAgent);
    return isBlackListedBrowser || ((isIOS() || isMac()) && !!userAgent.match(/(duckduckgo)/gi));
}

/**
 * This isn't used yet. The idea is that we could check for IOS and Android
 * and if neither are true, then we can do something for desktop users.
 */
export function isAndroid() {
    const userAgent = window.navigator.userAgent || window.navigator.vendor;
    const androidRegex = /android/gi;
    return !!userAgent && androidRegex.test(userAgent);
}

export function isSamsungBrowser() {
    const userAgent = window.navigator.userAgent || window.navigator.vendor;
    const regex = /samsungbrowser/gi;
    return !!userAgent && regex.test(userAgent);
}

export function isDuckDuckGoBrowser() {
    const userAgent = window.navigator.userAgent || window.navigator.vendor;
    const regex = /duckduckgo/gi;
    return !!userAgent && regex.test(userAgent);
}

export function isFirefoxBrowser() {
    const userAgent = window.navigator.userAgent || window.navigator.vendor;
    const regex = /FxiOS/gi;
    return !!userAgent && regex.test(userAgent);
}

export function isSafariDesktop() {
    return (
        window.navigator.userAgent.includes("Safari") &&
        !window.navigator.userAgent.includes("Chrome") &&
        window.navigator.maxTouchPoints === 0
    );
}

export function isMobile() {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true;
    } else {
        return false;
    }
}

export function isIOS17OrAbove() {
    if (!isIOS() || !isMobile()) {
        return false;
    }

    // Pattern to search for "iPhone OS XX_X_X" in the user agent string
    // This will appear in the user agent string for iOS devices
    const iosVersionMatch = navigator.userAgent.match(/iPhone OS (\d+_\d+(_\d+)?)/);

    if (!iosVersionMatch || iosVersionMatch.length < 2) {
        return false;
    }

    const majorVersion = parseInt(iosVersionMatch[1].split("_")[0]);

    if (majorVersion >= 17) {
        return true;
    }
}
