import { AnalyticsPageEvent, AnalyticsTrackEvent } from "common/types/graphql";

import cuid from "cuid";
import { Queue } from "../../util/queue";

interface ZarazSetOptions {
    scope: "page" | "session" | "persist";
}

interface ZarazServices {
    track: (eventName: string, properties: object) => void;
    set: (key: string, value: string, options?: ZarazSetOptions) => void;
}

class ZarazAnalytics {
    zaraz: ZarazServices;
    anonymousId: string;
    eventQueue: Queue<Partial<AnalyticsPageEvent> | Partial<AnalyticsTrackEvent>>;

    constructor(getZarazInstance: () => ZarazServices) {
        this.zaraz = getZarazInstance();
        this.anonymousId = cuid();

        // We put a queue in front of the zaraz track function to prevent track requests being received out of order.
        // We don't want to `await` each `zaraz.track` request on the main thread because that would block the UI.
        // Event ordering is important because our funnels in GA rely on the order of events.
        this.eventQueue = new Queue();
        this.eventQueue.setHandler((event) => this.processQueueItem(event)).startProcessing();
    }

    track(e: Partial<AnalyticsTrackEvent>): void {
        if (e.name) {
            e.type = "track";
            e.platform = "card_worker";
            e.anonymous_id = this.anonymousId;
            e.message_id = cuid();
            this.eventQueue.enqueue(e);
        }
    }

    page(e: Partial<AnalyticsPageEvent>): void {
        e.name = "page_view";
        e.type = "page";
        e.platform = "card_worker";
        e.message_id = cuid();
        e.anonymous_id = this.anonymousId;
        this.eventQueue.enqueue(e);
    }

    set(key: string, value: string, options?: ZarazSetOptions) {
        console.debug("Zaraz setting", { [key]: value, options });
        this.zaraz.set(key, value, options);
    }

    private async processQueueItem(
        item: Partial<AnalyticsPageEvent> | Partial<AnalyticsTrackEvent>
    ): Promise<void> {
        if (item.name) {
            try {
                await this.zaraz.track(item.name, item);
            } catch (e) {
                console.error("Error sending track event", e);
            }
        }
    }
}

function getZarazInstance() {
    if (window.zaraz) {
        return window.zaraz;
    }

    return {
        track: (itemName: string, item: object) => {
            console.warn("Track is not available, zaraz is not configured");
            // This log only shows up on localhost
            console.log({ itemName, item });
        },
        set: () => console.warn("Set is not available, zaraz is not configured"),
    };
}

export const analytics = new ZarazAnalytics(getZarazInstance);
